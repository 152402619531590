import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

type Props = {
  contentByosu: number;
  setContentByosu: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
};
const CONTENT_HUNSU = [] as number[];
for (let i = 0; i <= 60; i++) CONTENT_HUNSU.push(i);
const CONTENT_BYOSU = [0, 15, 30, 45];

/**
 * 動画秒数コンポーネント
 *
 * @param {Props} { contentByosu, setContentByosu, disabled = false }
 * @return {*} 
 */
const ContentByosu = ({ contentByosu, setContentByosu, disabled = false }: Props) => {
  const [contentHunsuSelect, setDogaHunsuSelect] = useState<number>(0);
  const [contentByosuSelect, setDogaByosuSelect] = useState<number>(0);

  const handleChangeHunsu = (event: any) => {
    const dogaHunsuSelectNew = +event.target.value;
    setDogaHunsuSelect(dogaHunsuSelectNew);
    setContentByosu((dogaHunsuSelectNew * 60) + contentByosuSelect);
  };
  const handleChangeByosu = (event: any) => {
    const contentByosuSelectNew = +event.target.value;
    setDogaByosuSelect(contentByosuSelectNew);
    setContentByosu((contentHunsuSelect * 60) + contentByosuSelectNew);
  };

  useEffect(() => {
    if (contentByosu !== 0) {
      setDogaHunsuSelect(Math.floor(contentByosu / 60));
      setDogaByosuSelect(contentByosu % 60);
    }
  }, [contentByosu])

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth sx={{ display: "flex", flexFlow: "row" }}>
        <TextField
          id="contentHunsuSelect"
          label="分"
          value={contentHunsuSelect}
          onChange={e => handleChangeHunsu(e)}
          required
          disabled={disabled}
          fullWidth
          sx={{ mr: 2 }}
          select>
          {CONTENT_HUNSU.map((contentHunsuSelect, index) => {
            return (
              <MenuItem key={index} value={contentHunsuSelect}>
                {contentHunsuSelect}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          id="contentByosuSelect"
          label="秒"
          value={contentByosuSelect}
          defaultValue=""
          onChange={e => handleChangeByosu(e)}
          required
          disabled={disabled}
          fullWidth
          select>
          {CONTENT_BYOSU.map((contentByosuSelect, index) => {
            return (
              <MenuItem key={index} value={contentByosuSelect}>
                {contentByosuSelect}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    </Box >
  );
}

export default ContentByosu;
