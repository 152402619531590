import React, { useContext } from "react";
import { Kengen } from "../../const/index";
import { Navigate } from "react-router-dom";

import { AuthInfoContext } from "../../context/AuthContext";

type Props = {
    component: React.ReactNode;
    allowroles?: Kengen[]
}

/**
 * 画面権限チェック処理
 * 遷移先画面の権限が無い場合、ログイン画面に遷移する。
 *
 * @param {Props} props
 * @return {*} 遷移先画面
 */
export const RouteAuthGuard: React.VFC<Props> = (props: Props) => {
    const [authInfo] = useContext(AuthInfoContext);

    let allowRoute = false;
    if (authInfo.user_id !== 0) {
        allowRoute = props.allowroles ? props.allowroles.includes(authInfo.kengen) : true;
    }

    // 権限が無い場合、ログイン画面に遷移する
    if (!allowRoute) {
        return <Navigate to="/" />
    }

    return <>{props.component}</>;
}