import {
  BangumihyoShosaiSearchParam,
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE, CUSTOM_ERROR_CODE } from "../const/index";

/**
 * bangumihyo_shosaiテーブルAPI
 * 番組表詳細検索
 *
 * @param {BangumihyoShosaiSearchParam} searchParam
 * @return {*} 
 */
export const getBangumihyoShosai = async (searchParam: BangumihyoShosaiSearchParam) => {
  const result = await API.get(`/cms-api/bangumihyoShosai`, { params: searchParam })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * bangumihyo_shosaiテーブルAPI
 * 番組表詳細保存
 *
 * @param {any} postData
 * @return {*} 
 */
export const postBangumihyoShosai = async (postData: any) => {
  const result: any = await API.post(`/cms-api/bangumihyoShosai`, postData)
    .then((res) => {
      return { isError: false, message: res.data.message, failedMailAddresses: res.data.failedMailAddresses, bangumihyoId: res.data.bangumihyoId };
    })
    .catch((error) => {
      const status = error.response.status;
      const customStatus = error.response.data.status;
      let message = "";
      if (customStatus === CUSTOM_ERROR_CODE.DB_UNIQUE_ERROR) {
        message = error.response.data.message
      } else if (status === HTTP_STATUS_CODE.CONFLICT_ERROR) {
        message = MESSAGE.CONFLICT_ERROR
      } else {
        message = MESSAGE.SYSTEM_ERROR
      }
      return { isError: true, message: message, bangumihyoId: postData.rows[0].bangumihyo_id };
    });
  return result;
};

/**
 * bangumihyo_shosaiテーブルAPI
 * サムネイル取得
 *
 * @param {string} thumbnail_path
 * @return {*} 
 */
export const getThumbnail = async (thumbnail_path: string) => {
  const result = await API.get(`/cms-api/bangumihyoShosai/download/thumbnail`, { params: { thumbnail_path } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};
