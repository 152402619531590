import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import { postSearchKokyakuDairiten } from "../../api/kokyaku";
import { KokyakuData, KokyakuDataSet, UserSettingDataSet } from "../../types/WebData";

type Props = {
    kokyaku: KokyakuDataSet | null;
    setKokyaku: React.Dispatch<React.SetStateAction<KokyakuDataSet | null>>;
    loginUser: UserSettingDataSet | null;
    label?: string;
    disabled?: boolean;
};

/**
 * 広告主セレクトボックスコンポーネント
 *
 * @param {Props} { kokyaku, setKokyaku, loginUser, label, disabled = false }
 * @return {*} 
 */
const KokyakuSelect = ({ kokyaku, setKokyaku, loginUser, label = "広告主名", disabled = false }: Props) => {
    const [authInfo] = useContext(AuthInfoContext);
    const reloadContext = useContext(ReloadContext);
    const [kokyakuData, setKokyakuData] = useState<KokyakuData>({ data: [] });

    // 初期化処理
    useEffect(() => {
        let isMounted = true;
        setKokyakuData({ data: [] });
        const getKokyakuData = async () => {
            const searchParam = {
                jusho: "",
                kokyaku_mei: "",
                is_visible: false,
                loginuser_id: loginUser ? loginUser.id : authInfo.id,
                kaisha_id: loginUser ? loginUser.kaisha_id : authInfo.kaisha_id,
                id: kokyaku?.id
            };
            const result = await postSearchKokyakuDairiten(searchParam);
            if (result.isError) {
                return reloadContext?.setSnackbarInfo({
                    isOpen: true,
                    type: "error",
                    message: result.message,
                });
            }
            const blank = { id: 0 } as KokyakuDataSet;
            result.data.unshift(blank);
            setKokyakuData(result);
        };
        if (isMounted) getKokyakuData();
        return () => { isMounted = false };
    }, [loginUser]);

    const handleChange = (event: SelectChangeEvent) => {
        const kokyaku = kokyakuData.data.find(kokyaku => event.target.value === String(kokyaku.id))
        if (!kokyaku) return;
        setKokyaku(kokyaku);
    };

    return (
        <Box sx={{ minWidth: 120 }} mt={1}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={(kokyaku) ? String(kokyaku.id) : ""}
                    label={label}
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {kokyakuData.data.map((kokyakuData) => {
                        return (
                            <MenuItem key={kokyakuData.id} value={String(kokyakuData.id)} sx={{ height: "36px" }}>
                                {kokyakuData.kokyaku_mei}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}

export default KokyakuSelect;