import API from "./api";
import * as MESSAGE from "../const/message";

/**
 * oshiraseテーブルAPI get
 * 一覧取得
 *
 * @param {number} loginuserId
 */
export const getOshirase = async (loginuserId: number) => {
  const result = await API.get(`/cms-api/oshirase`, { params: { loginuser_id: loginuserId } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * oshiraseテーブルAPI get
 * 件数取得
 *
 * @param {number} loginuserId
 * @param {boolean} midokuFlg
 */
export const getOshiraseCount = async (loginuserId: number, midokuFlg?: boolean) => {
  const result = await API.get(`/cms-api/oshirase/count`, { params: { loginuser_id: loginuserId, midoku_flg: midokuFlg } })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * oshiraseテーブルAPI 既読フラグ更新
 *
 * @param {number} id
 */
export const updateOshirase = async (id: number) => {
  // update
  const result = await API.put("/cms-api/oshirase/id", { id })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * oshiraseテーブルAPI del_flg更新
 *
 * @param {number} id
 */
export const deleteOshirase = async (id: number) => {
  const result = await API.put("/cms-api/oshirase/delete", { id: id })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * oshiraseテーブルAPI 一括del_flg更新
 *
 * @param {number} loginuserId
 */
export const deleteAllOshirase = async (loginuserId: number) => {
  const result = await API.put("/cms-api/oshirase/deleteAll", { loginuser_id: loginuserId })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};