import { useState, useContext } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { ReloadContext } from "../../context/ReloadContext";
import { postPasswordReminder } from "../../api/loginuser";

export interface IFormValues {
  user_id: string;
}

/**
 * パスワード忘れダイアログコンポーネント
 * パスワード再設定画面のURLを記載したメールを送信する。
 *
 * @return {*} 
 */
const PasswordReminderDialog = () => {
  const reloadContext = useContext(ReloadContext);
  const [userId, setUserId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setErrorMessage("");
    setUserId("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMail = async () => {
    // バリデーションチェック
    if (!userId) {
      return setErrorMessage("担当者IDを入力してください。");
    }

    const result = await postPasswordReminder(userId);

    reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    handleClose();
  }

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Link href="#" variant="body2" onClick={handleClickOpen}>
        パスワードをお忘れの方
      </Link>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <Typography component="h3" variant="subtitle1" mt={2} ml={2}>
          パスワードをお忘れの方
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" mb={2}>
            登録したメールアドレスにパスワード再設定用のURLを送信します
          </DialogContentText>
          {errorMessage && (
            <small style={{ color: "red", marginLeft: "16px" }}>{errorMessage}</small>
          )}
          <TextField
            margin="dense"
            id="user_id"
            label="担当者ID"
            value={userId}
            onChange={e => setUserId(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <Button variant="contained" onClick={handleSendMail}>メールを送信する</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default PasswordReminderDialog;