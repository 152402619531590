import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import { getContentDownload, getContent } from "../../api/content";
import { KENGEN, UPLOAD_STATUS } from "../../const/index";
import * as MESSAGE from "../../const/message";
import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  baitaiKanriKaishaId: number;
  moshikomiKaishaId: number;
  omakasePackFlg: boolean;
  contentId: number;
  henshuContentId: number;
  contentFilePath: string;
  henshuContentFilePath: string;
  contentFileMei: string;
  henshuContentFileMei: string;
};

/**
 * ダウンロード確認ダイアログコンポーネント
 *
 * @param {Props} { contentId, henshuContentId, contentFilePath, henshuContentFilePath, contentFileMei, henshuContentFileMei }
 * @return {*} 
 */
const DownloadConfirmDialog = ({
  baitaiKanriKaishaId,
  moshikomiKaishaId,
  omakasePackFlg,
  contentId,
  henshuContentId,
  contentFilePath,
  henshuContentFilePath,
  contentFileMei,
  henshuContentFileMei
}: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [authInfo] = useContext(AuthInfoContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedContentId, setSelectedContentId] = useState(0);
  const [selectedContentFilePath, setSelectedContentFilePath] = useState("");
  const [selectedContentFileMei, setSelectedContentFileMei] = useState("");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    setIsDownloading(false);
  }, [])

  const handleOpenMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = async (contentId: number, contentFilePath: string, contentFileMei: string) => {
    if (!contentId) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: "まだコンテンツがアップロードされていません。",
      });
    };
    const content = (await getContent(contentId)).data[0];

    if (content.upload_status === UPLOAD_STATUS.UPLOADING) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: MESSAGE.WAIT_FOR_UPLOADING,
      });
    };
    if (content.upload_status === UPLOAD_STATUS.UPLOAD_FAILED) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "アップロードに失敗したため、ダウンロードできません。\n再度アップロードしてください。",
      });
    };
    setSelectedContentId(contentId);
    setSelectedContentFilePath(contentFilePath);
    setSelectedContentFileMei(contentFileMei);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDownload = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setIsDownloading(true);
    setLoad(true);
    let result;
    try {
      // キャンセルトークン生成
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      // ダウンロード中state更新
      reloadContext?.setDownloadingInfo({ isDownloading: true, source });
      result = await getContentDownload(source, selectedContentId, selectedContentFilePath, authInfo.kengen);
      // ダウンロード中フラグOFF
      reloadContext?.setDownloadingInfo({ isDownloading: false });
    } catch (error) {
      handleClose();
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: MESSAGE.SYSTEM_ERROR,
      });
    }

    const blob = new Blob([result.data], { type: 'application/octet-binary' });
    const downloadUrl = URL.createObjectURL(blob);

    // ダウンロード処理
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = selectedContentFileMei;
    a.click();
    a.remove()
    reloadContext?.setReload(reloadContext?.reload + 1);
    setIsDownloading(false);
    setLoad(false);
    handleClose();
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        disabled={authInfo.kengen !== KENGEN.ADMIN && (authInfo.kaisha_id === baitaiKanriKaishaId ? omakasePackFlg && authInfo.kaisha_id !== moshikomiKaishaId ? true : false : false)
        }
        color="inherit"
      >
        {
          authInfo.kengen === KENGEN.KANRI ?
            <CloudDownloadIcon
              color={authInfo.kengen === KENGEN.ADMIN ? "primary" : (authInfo.kaisha_id === baitaiKanriKaishaId ? omakasePackFlg && authInfo.kaisha_id !== moshikomiKaishaId ? "disabled" : "primary" : "primary")}

              fontSize="large"
              onClick={() => handleClickOpen(henshuContentId, henshuContentFilePath, henshuContentFileMei)} />
            : <CloudDownloadIcon 
            color={authInfo.kengen === KENGEN.ADMIN ? "primary" : (authInfo.kaisha_id === baitaiKanriKaishaId ? omakasePackFlg && authInfo.kaisha_id !== moshikomiKaishaId ? "disabled" : "primary" : "primary")}

            fontSize="large" onClick={handleOpenMenu} />
        }
      </IconButton>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "20vw",
          }
        }}
      >
        <Typography component="h3" variant="subtitle1" mt={2} ml={2}>
          ダウンロード確認
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            コンテンツファイルをダウンロードしてもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={handleClickDownload} disabled={isDownloading}>ダウンロードする</LoadingButton>
        </DialogActions>
      </Dialog>
      <Menu
        id={`menu-appbar`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleClickOpen(contentId, contentFilePath, contentFileMei)}>コンテンツダウンロード</MenuItem>
        <MenuItem onClick={() => handleClickOpen(henshuContentId, henshuContentFilePath, henshuContentFileMei)}>編集コンテンツダウンロード</MenuItem>
      </Menu>
    </Box >
  );
}

export default DownloadConfirmDialog;
