import { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import SimpleDatePicker from '../Common/DatePicker/SimpleDatePicker';
import { getExistBangumihyo } from "../../api/bangumihyo";
import { BangumihyoSearchParam, BaitaiDataSet, BaitaiShosaiDataSet } from "../../types/WebData";
import { BANGUMIHYO_STATUS } from "../../const/index";
export interface IFormValues {
  user_id: string;
}

type Props = {
  baitai: BaitaiDataSet | null,
  baitaiShosai: BaitaiShosaiDataSet | null,
  searchParam: BangumihyoSearchParam | null;
};

/**
 * 番組表新規作成ダイアログコンポーネント
 *
 * @param {Props} { baitaiShosai, searchParam }
 * @return {*} 
 */
const BangumihyoCreateDialog = ({ baitai, baitaiShosai, searchParam }: Props) => {
  const navigate = useNavigate();
  const reloadContext = useContext(ReloadContext);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handleClickOpen = () => {
    if (!baitaiShosai?.id) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "画面を選択してください。",
      });
    }
    setErrorMessage("");
    setStartDate(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    // バリデーションチェック
    if (!startDate) {
      return setErrorMessage("放映開始日を入力してください。");
    }
    if (!moment(startDate).isValid()) {
      return setErrorMessage("不正な入力形式です。");
    }
    setLoad(true);
    const result = await getExistBangumihyo(moment(startDate).format('YYYY-MM-DD'), baitaiShosai?.id);

    if (result.isError) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: result.message,
      });
    }

    if (result.data > 0) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: `${moment(startDate).format('YYYY/MM/DD')}放映開始の番組表は既に存在しているため、新規作成できません。`,
      });
    }

    navigate("/bangumihyoShosai",
      {
        state:
        {
          baitai_id: searchParam?.baitai_id,
          baitai_mei: baitai?.baitai_mei,
          hoei_kaishi_jikan: baitai?.hoei_kaishi_jikan,
          hoei_shuryo_jikan: baitai?.hoei_shuryo_jikan,
          baitai_shosai_id: baitaiShosai?.id,
          gamen_mei: baitaiShosai?.gamen_mei,
          size: baitaiShosai?.size,
          haishin_kaishibi: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS\Z"),
          status: BANGUMIHYO_STATUS.SAKUSEICHU,
          searchParam
        }
      });
    setLoad(false);
  }

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Button
        type="button"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ mt: 2, mb: 2 }}
      >
        新規作成
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <Typography component="h3" variant="h6" mt={2} ml={2}>
          番組表新規作成
        </Typography>
        <DialogContent>
          <SimpleDatePicker
            date={startDate}
            setDate={setStartDate}
            label="放映開始日 *"
            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
          />
          {errorMessage && (
            <small style={{ color: "red", marginLeft: "16px" }}>{errorMessage}</small>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={handleCreate}>新規作成</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default BangumihyoCreateDialog;
