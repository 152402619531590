import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { KikiShubetsuDataSet, CodeMasterData, CodeMasterDataSet, CodeMasterSearchParam } from "../../types/WebData";
import { postSearchCodemaster } from '../../api/codemaster';
import { ReloadContext } from "../../context/ReloadContext";
import { CODE_MASTER_CATEGORY } from "../../const/index";

type Props = {
  kikiShubetsu: KikiShubetsuDataSet | null;
  setKikiShubetsu: React.Dispatch<React.SetStateAction<KikiShubetsuDataSet | null>>;
  label?: string;
  disabled?: boolean;
};

/**
 * 機器種別セレクトボックスコンポーネント
 *
 * @param {Props} { kikiShubetsu, setKikiShubetsu, label, disabled = false }
 * @return {*} 
 */
const KikiShubetsuSelect = ({ kikiShubetsu, setKikiShubetsu, label = "機器種別", disabled = false }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [kikiShubetsuData, setKikiShubetsuData] = useState<CodeMasterData>({ data: [] });

  // 初期化処理
  useEffect(() => {
    const getKikiShubetsuData = async () => {
      const searchParam: CodeMasterSearchParam = { category_id: CODE_MASTER_CATEGORY.KIKI_SHUBETSU }
      const result = await postSearchCodemaster(searchParam)
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const blank = { id: 0, code: 0, code_name: "" } as CodeMasterDataSet;
      result.data.unshift(blank);
      setKikiShubetsuData(result);
    }
    setTimeout(() => {
      getKikiShubetsuData();
    }, 0);
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const kikiShubetsu = kikiShubetsuData.data.find(kikiShubetsu => event.target.value === String(kikiShubetsu.code))
    if (!kikiShubetsu) return;
    setKikiShubetsu(kikiShubetsu);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(kikiShubetsu) ? String(kikiShubetsu.code) : ""}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {kikiShubetsuData.data.map((kikiShubetsu) => {
            return (
              <MenuItem key={kikiShubetsu.code} value={String(kikiShubetsu.code)} sx={{ height: "36px" }}>
                {kikiShubetsu.code_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default KikiShubetsuSelect;