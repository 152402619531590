import { useState, useContext, useEffect } from "react"
import { useNavigate, useLocation, Outlet, Location } from "react-router-dom";

import { AxiosRequestConfig } from "axios";
import lscache from "lscache";

import {
  CssBaseline,
  Box,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { jaJP } from "@mui/x-data-grid";

import API from "../../api/api";
import { postTokenReflesh } from "../../api/loginuser";
import { postAccessLog } from "../../api/accessLog";
import SideMenu from "./SideMenu";
import SimpleSnackbars from "./SimpleSnackbars";
import OshiraseDialog from "../Oshirase/OshiraseDialog";
import { LoggedInContext, AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";
import useLocationChange from '../../hooks/useLocationChange'
import { KENGEN } from "../../const";
import styleCss from "./BaseLayout.module.css";
import * as MESSAGE from "../../const/message";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({}, jaJP);

/**
 * ベースレイアウトコンポーネント
 *
 * @return {*} 
 */
export const BaseLayout = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const isLoggedIn = useContext(LoggedInContext);
  const reload = useContext(ReloadContext);
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pathname, setPathname] = useState<string>("");
  const [menuName, setMenuName] = useState<string>("");
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [countMidoku, setCountMidoku] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  /**
   * リクエスト前にログイン有効期限をチェックする処理
   *
   * @param {AxiosRequestConfig} config
   * @return {*} 
   */
  const authRequestInterceptor = (config: AxiosRequestConfig) => {
    const accessToken = lscache.get("accessToken");
    const authInfo = lscache.get("authInfo");

    if (authInfo) {
      // localStorageの有効期限30分リセット
      // （補足）API呼び出しの度に有効期限リセット→API操呼び出し無い状態が30分続いたらログアウト。
      lscache.set("createNewExpiration", "", 30);
      const cacheExpiration = localStorage.getItem("lscache-createNewExpiration-cacheexpiration");
      lscache.remove("createNewExpiration");

      if (cacheExpiration) {
        localStorage.removeItem("lscache-authInfo-cacheexpiration");
        localStorage.setItem('lscache-authInfo-cacheexpiration', cacheExpiration);
      }
    } else {
      reload?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: MESSAGE.EXPIRED_AUTH_INFO,
      });
      // ログインページに遷移
      navigate('/');
    }

    if (accessToken && config.headers) {
      const refleshToken = lscache.get("refleshToken");
      config.headers.authorization = `Bearer ${(config.url === "/cms-api/loginuser/tokenReflesh") ? refleshToken : accessToken}`;
    }
    return config;
  }

  const authResponseInterceptor = (response: any) => {
    return response;
  }

  const authResponseInterceptorError = async (error: any) => {
    // アクセストークン有効期限切れ以外のエラーなら通常のエラー処理に流す
    if (error.response.data.error_code !== "expired_access_token") return Promise.reject(error);

    // トークンリフレッシュ処理失敗ならログイン画面に遷移
    if (error.response.data.error_code === "token_reflesh_failed") {
      reload?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: MESSAGE.EXPIRED_AUTH_INFO,
      });
      // ログインページに遷移
      navigate('/');
    }

    const authInfo = lscache.get("authInfo");
    const refleshToken = lscache.get("refleshToken");

    // authInfo、リフレッシュトークンが有効な場合
    if (refleshToken && authInfo) {
      let result;
      result = await postTokenReflesh(authInfo.user_id)

      if (result && result.accessToken && result.refleshToken) {
        // localStorageの有効期限 24時間
        lscache.remove("accessToken");
        lscache.set("accessToken", result.accessToken);
        // localStorageの有効期限 25時間
        lscache.remove("refleshToken");
        lscache.set("refleshToken", result.refleshToken);

        // リトライ処理
        await API.request(error.config)
      }
      return Promise.resolve({ data: [] });
    } else {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: MESSAGE.EXPIRED_AUTH_INFO,
      });
      // ログインページに遷移
      navigate('/');
      return Promise.resolve({ data: [] });
    }
  }

  // 初期化処理
  useEffect(() => {
    // axiosのリクエスト実行前共通処理
    const requestId = API.interceptors.request.use(authRequestInterceptor);

    // axiosの共通エラーハンドリング
    const responseId = API.interceptors.response.use(
      authResponseInterceptor, authResponseInterceptorError
    );

    // 初期化処理完了
    setIsInitialized(true);

    // クリーンアップ関数
    // ベースレイアウト画面破棄する際に上記のaxios処理の登録を解除する
    return () => {
      API.interceptors.request.eject(requestId);
      API.interceptors.response.eject(responseId);
    }
  }, []);

  // タイトル表示 & 表示中のメニューを青くする
  useEffect(() => {
    let menuName = "";
    // サイドメニューのタイトルはDBから取得　サイドメニューに無い画面のタイトルはべた書き
    if (pathname === "/contentUpload") {
      menuName = "コンテンツアップロード";
    } else if (pathname === "/bangumihyoShosai") {
      menuName = "番組表詳細";
    } else if (pathname === "/jackShosai") {
      menuName = "ジャック詳細";
    } else {
      // 管理会社の場合、放映申込 → コンテンツ管理に名称変更
      const menuNameCurrent = reload?.menuData.data.find(d => d.isCurrent)?.menu_name;
      if (menuNameCurrent) {
        menuName = (menuNameCurrent === "放映申込" && authInfo.kengen === KENGEN.KANRI) ? "コンテンツ管理" : menuNameCurrent;
      } else {
        // isCurrentのメニューがない=別タブ表示やF5リロードとみなして、pathnameをカレントページとしてタイトルを設定する
        const menuName_ = reload?.menuData.data.find(d => d.contents_path === pathname);
        if (menuName_) {
          menuName = (menuName_.menu_name === "放映申込" && authInfo.kengen === KENGEN.KANRI) ? "コンテンツ管理" : menuName_.menu_name;
          reload?.setMenuData({
            data: reload?.menuData.data.map(d => {
              return {
                ...d,
                isCurrent: d.contents_path === menuName_.contents_path
              }
            })
          });
        }
      }
    }
    setMenuName(menuName);
  }, [pathname]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    setAuthInfo({
      id: 0,
      user_id: 0,
      shimei: "",
      kengen: 0,
      kaisha_id: 0,
      haishin_tanto_flg: false
    });
    setAnchorEl(null);
    navigate("/");
  };

  // ルートが変わった（画面遷移した）際の処理
  useLocationChange((location: Location) => {
    setPathname(location.pathname)
    reload?.setMenuData({
      data: reload?.menuData.data.map(d => {
        return {
          ...d,
          isCurrent: d.contents_path === location.pathname
        }
      })
    })
    // ログインユーザID期限切れのデータを削除
    lscache.flushExpired();

    // ログインユーザIDが取得できない場合、ログインページへリダイレクト
    if (state?.before_path !== "loginForm" && !lscache.get("authInfo")) {
      reload?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: MESSAGE.EXPIRED_AUTH_INFO,
      });
      navigate("/");
    } else {
      if (state) {
        state.before_path = null;
      }
    }

    // アクセスログAPI呼び出し
    const postAccessLogAsync = async () => {
      await postAccessLog(authInfo.id, location.pathname);
    }
    postAccessLogAsync();
  })

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {menuName}
            </Typography>
            {isLoggedIn ? (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {countMidoku !== 0 ?
                    <span className={styleCss.accountCount}>
                      {countMidoku}
                    </span>
                    : ""}
                  <AccountCircle fontSize="large" />
                </IconButton>
                {authInfo?.shimei}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <OshiraseDialog countMidoku={countMidoku} setCountMidoku={setCountMidoku} />
                  <MenuItem className={styleCss.acountMenu} onClick={handleSignOut}>ログアウト</MenuItem>
                </Menu>
              </div>
            ) : (
              ""
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          ModalProps={{ keepMounted: true }}
          open={open}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <SideMenu />
        </Drawer>
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          {isInitialized ? <Outlet /> : ""}
          <SimpleSnackbars />
        </Box>
      </Box>
    </ThemeProvider >
  );
};
