import { useState, useContext, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { ReloadContext } from "../../context/ReloadContext";
import { MenuData, MenuDataSet } from "../../types/WebData";
import { getMenu } from "../../api/menu";
import { KENGEN } from "../../const/index";

/**
 * メニュー利用一覧コンポーネント
 *
 * @return {*} 
 */
const MenuUsageGrid = () => {
  const [menuData, setMenuData] = useState<MenuData>({ data: [] });
  const reload = useContext(ReloadContext);

  /**
   * 権限設定画面メニュー利用設定グリッド
   *
   * @param {MenuDataSet[]} menus
   * @return {*} 
   */
  const setMenuUsageGrid = (menus: MenuDataSet[]) => {
    return menus
      .filter((option) => (option.kengen_id == null || option.kengen_id === 1))
      .map((menu) => {
        return menu.kengen_id === null
          ? {
            id: menu.id,
            menu_name: menu.menu_name,
            user_admin: false,
            user_dairiten: false,
            user_kanri: false,
            user_omakase: false,
          }
          : {
            id: menu.id,
            menu_name: menu.menu_name,
            user_admin:
              menus.some((a) => a.kengen_id === KENGEN.ADMIN && a.id === menu.id && a.riyo_flg === true) ? true : false,
            user_dairiten:
              menus.some((a) => a.kengen_id === KENGEN.DAIRITEN && a.id === menu.id && a.riyo_flg === true) ? true : false,
            user_kanri:
              menus.some((a) => a.kengen_id === KENGEN.KANRI && a.id === menu.id && a.riyo_flg === true) ? true : false,
            user_omakase:
              menus.some((a) => a.kengen_id === KENGEN.OMAKASE && a.id === menu.id && a.riyo_flg === true) ? true : false,
          };
      });
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "menu_name",
      headerName: "メニュー",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "user_admin",
      headerName: "システム管理",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      type: "boolean",
    },
    {
      field: "user_dairiten",
      headerName: "広告代理店",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      type: "boolean",
    },
    {
      field: "user_kanri",
      headerName: "管理会社",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      type: "boolean",
    },
  ];

  useEffect(() => {
    const getMenuData = async () => {
      const result = await getMenu();
      if (result.isError) {
        return reload?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setMenuData(result);
    };
    getMenuData();
    return () => {
      reload?.setReload(0);
    };
  }, [reload]);

  return (
    <div style={{ height: '80vh', width: "100%" }}>
      <p>メニュー利用設定</p>
      <DataGrid
        rows={setMenuUsageGrid(menuData.data)}
        columns={columns}
        hideFooter={true}
        sx={{ maxHeight: "90%" }}
      />
    </div>
  );
};

export default MenuUsageGrid;