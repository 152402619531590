import { BaitaiDataSet, BaitaiSearchParam, BaitaiSelectSearchParam } from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * baitaiテーブルAPI get
 *
 * @param {number} baitaiId
 */
export const getBaitaiId = async (baitaiId: number) => {
  const result = await API.get(`/cms-api/baitai/id`, { params: { baitai_id: baitaiId } })
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });

  return result;
};

/**
 * baitaiテーブルAPI
 * 媒体情報検索（媒体セレクトボックス用）
 *
 * @param {BaitaiSelectSearchParam} searchParam
 * @return {*} 
 */
export const postSearchBaitaiSelect = async (searchParam: BaitaiSelectSearchParam) => {
  const result = await API.post(`/cms-api/baitai/search/selectBox`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * baitaiテーブルAPI
 * 媒体情報検索
 *
 * @param {BaitaiSearchParam} searchParam
 * @return {*} 
 */
export const postSearchBaitai = async (searchParam: BaitaiSearchParam) => {
  const result = await API.post(`/cms-api/baitai/search`, searchParam)
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * baitaiテーブルAPI 更新
 *
 * @param {BaitaiDataSet} postData
 * @return 
 */
export const updateBaitai = async (postData: BaitaiDataSet) => {
  if (postData.id === 0) {
    // insert
    const result = await API.post("/cms-api/baitai", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO };
      })
      .catch((error) => {
        return { isError: true, message: MESSAGE.SYSTEM_ERROR };
      });
    return result;
  } else {
    // update
    const result = await API.put("/cms-api/baitai/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.KOSHIN_SEIKO };
      })
      .catch((error) => {
        const status = error.response.status;
        const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
        return { isError: true, message: message };
      });
    return result;
  }
};

/**
 * baitaiテーブルAPI
 * STBコンフィグファイル ダウンロード
 *
 * @param {number} baitaiId
 * @return 
 */
export const postSTBConfigDownload = async (baitaiId: number) => {
  const result = await API.post(`/cms-api/baitai/download/STBConfig`, { baitai_id: baitaiId })
    .then((res) => {
      return { isError: false, data: res.data, message: "" };
    })
    .catch((error) => {
      return { isError: true, data: null, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * baitaiテーブルAPI del_flg更新
 *
 * @param {number} id
 * @param {boolean} del_flg
 * @return 
 */
export const deleteBaitai = async (id: number, del_flg: boolean) => {
  const result = await API.put("/cms-api/baitai/delete", { id: id, del_flg: del_flg })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * baitaiテーブルAPI 媒体の存在チェック
 *
 * @param {number} location_id
 * @param {number} baitai_shubetsu_id
 */
export const isBaitai = async (location_id?: number, baitai_shubetsu_id?: number) => {
  const result = await API.post("/cms-api/baitai/count", { location_id: location_id, baitai_shubetsu_id: baitai_shubetsu_id })
    .then((res) => {
      return { isError: false, message: Number(res.data) > 0 ? location_id ? MESSAGE.EXISTENCE_LOCATION : MESSAGE.EXISTENCE_BAITAISHUBETU : '', data: res.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: '' };
    });
  return result;
};