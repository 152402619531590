import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { postSearchLocation } from "../../api/location";
import { ReloadContext } from "../../context/ReloadContext";
import { LocationData, LocationDataSet } from "../../types/WebData";

type Props = {
  location: LocationDataSet | null;
  setLocation: React.Dispatch<React.SetStateAction<LocationDataSet | null>>;
  label?: string;
  disabled?: boolean;
};

/**
 * 設置場所セレクトボックスコンポーネント
 *
 * @param {Props} { location, setLocation, label, disabled = false }
 * @return {*} 
 */
const LocationSelect = ({ location, setLocation, label = "設置場所名", disabled = false }: Props) => {
  const [locationData, setLocationData] = useState<LocationData>({ data: [] });
  const reloadContext = useContext(ReloadContext);

  // 初期化処理
  useEffect(() => {
    let isMounted = true;
    const getLocationData = async () => {
      const searchParam = { id: location?.id, jusho: "", location_mei: "", is_visible: false };
      const result = await postSearchLocation(searchParam);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const blank = { id: 0 } as LocationDataSet;
      result.data.unshift(blank);
      setLocationData(result);
    };
    if (isMounted) getLocationData();
    return () => { isMounted = false };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const baitai = locationData.data.find(location => event.target.value === String(location.id))
    if (!baitai) return;
    setLocation(baitai);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(location) ? String(location.id) : ""}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {locationData.data.map((location) => {
            return (
              <MenuItem key={location.id} value={String(location.id)} sx={{ height: "36px" }}>
                {location.location_mei}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default LocationSelect;