import { useState, useContext, useRef } from "react";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import moment from "moment";
import { ReloadContext } from "../../context/ReloadContext";
import { postJackHaishin } from "../../api/jack";
import { JACK_STATUS } from "../../const/index";
import { JackDataSet } from "../../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  selectedRow: JackDataSet | null;
}

/**
 * 配信確認ダイアログコンポーネント
 *
 * @param {Props} { selectedRow }
 * @return {*} 
 */
const JackHaishinConfirmDialog = ({ selectedRow }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [load, setLoad] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickOpen = async () => {
    // バリデーションチェック
    if (!selectedRow) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "配信するジャックを選択してください。",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * 配信処理
   *
   * @return {*} 
   */
  const handleHaishin = async () => {

    let errorMessage = "";
    let jackId = 0;

    if (!selectedRow) return;

    // バリデーションチェック
    if (selectedRow.status !== JACK_STATUS.SAKUSEICHU && selectedRow.status !== JACK_STATUS.HAISHINZUMI) {
      errorMessage = "放映済/未配信のジャックは配信できません。";
    } else {
      jackId = selectedRow.id;
    }

    if (errorMessage) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: errorMessage,
      });
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const result = await postJackHaishin(jackId, selectedRow?.jack_haishinbi);

    reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    reloadContext?.setReload(reloadContext?.reload + 1);
    setLoad(false);
    setOpen(false);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <LoadingButton
        loading={load}
        type="button"
        color="warning"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ ml: 1, mt: 2, mb: 2 }}
      >
        配信
      </LoadingButton>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "20vw",
          }
        }}
      >
        <Typography component="h3" variant="h6" mt={2} ml={2}>
          配信確認
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {moment(selectedRow?.jack_haishinbi).format('YYYY/MM/DD')}配信予定の番組表を配信してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={handleHaishin}>配信する</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default JackHaishinConfirmDialog;
