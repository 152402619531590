import React, { useContext } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import { LoginForm } from "./pages/LoginForm";
import { Hoeimoshikomi } from "./pages/Hoeimoshikomi";
import { Bangumihyo } from "./pages/Bangumihyo";
import { BangumihyoShosai } from "./pages/BangumihyoShosai";
import { Jack } from "./pages/Jack";
import { JackShosai } from "./pages/JackShosai";
import { ContentUpload } from "./pages/ContentUpload";
import { Kokyaku } from "./pages/Kokyaku";
import { Area } from "./pages/Area";
import { Location } from "./pages/Location";
import { UserSetting } from "./pages/UserSetting";
import { KikiKataban } from "./pages/KikiKataban";
import { Baitai } from "./pages/Baitai";
import { Kaizodo } from "./pages/Kaizodo";
import { TentoShomei } from "./pages/TentoShomei";
import { Kaisha } from "./pages/Kaisha";
import { Kengen } from "./pages/Kengen";
import { HoeiShomei } from "./pages/HoeiShomei"
import { BaitaiShubetsu } from "./pages/BaitaiShubetsu"
import { PasswordReSetting } from "./pages/PasswordReSetting";
import { BaseLayout } from "./components/Common/BaseLayout";

import { AuthInfoContext } from "./context/AuthContext";
import { RouteAuthGuard } from "./components/Common/RouteAuthGuard";
import { MenuDataSet } from "./types/WebData";

/**
 * ルーティング設定コンポーネント
 *
 * @return {*} 
 */
export const RouterConfig: React.FC = () => {
  const [authInfo] = useContext(AuthInfoContext);

  const getElement = (contentsPath: string) => {
    return contentsPath === "/hoeimoshikomi" ? (
      <Hoeimoshikomi />
    ) : contentsPath === "/kokyaku" ? (
      <Kokyaku />
    ) : contentsPath === "/bangumihyo" ? (
      <Bangumihyo />
    ) : contentsPath === "/jack" ? (
      <Jack />
    ) : contentsPath === "/userSetting" ? (
      <UserSetting />
    ) : contentsPath === "/baitai" ? (
      <Baitai />
    ) : contentsPath === "/kaizodo" ? (
      <Kaizodo />
    ) : contentsPath === "/kikiKataban" ? (
      <KikiKataban />
    ) : contentsPath === "/kaisha" ? (
      <Kaisha />
    ) : contentsPath === "/area" ? (
      <Area />
    ) : contentsPath === "/hoeiShomei" ? (
      <HoeiShomei />
    ) : contentsPath === "/tentoShomei" ? (
      <TentoShomei />
    ) : contentsPath === "/location" ? (
      <Location />
    ) : contentsPath === "/kengen" ? (
      <Kengen />
    ) : contentsPath === "/baitaiShubetsu" ? (
      <BaitaiShubetsu />
    ) : null;
  }

  return (
    <>
      <HashRouter>
        <Routes>
          <Route index element={<LoginForm />} />
          <Route path="/" element={<BaseLayout />} >
            {
              authInfo?.menuData?.data.map((menu: MenuDataSet) => {
                if (
                  menu.kengen_id === authInfo?.kengen
                ) {
                  return (
                    <Route path={menu.contents_path} element={<RouteAuthGuard component={getElement(menu.contents_path)}
                      allowroles={authInfo?.menuData?.data.filter((d1: MenuDataSet) => (d1.contents_path === menu.contents_path && d1.riyo_flg)).map(d2 => d2.kengen_id)} />} key={menu.contents_path} />
                  );
                } else {
                  return null;
                }
              })
            }
            <Route path="contentUpload" element={<ContentUpload />} />
            <Route path="bangumihyoShosai" element={<BangumihyoShosai />} />
            <Route path="jackShosai" element={<JackShosai />} />
          </Route>
          <Route path="passwordReSetting" element={<PasswordReSetting />} />
        </Routes>
      </HashRouter>
    </>
  );
}