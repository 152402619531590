import React, { useState, useRef, useContext } from "react";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  Box,
  Toolbar,
  Grid,
  TextField,
} from "@mui/material";

import JSZip from 'jszip';

import { ReloadContext } from "../context/ReloadContext";
import { postSTBConfigDownload } from "../api/baitai";
import { Copyright } from "../components/Common/Copyright";
import BaitaiGrid from '../components/Baitai/BaitaiGrid';
import BaitaiEditDialog from '../components/Baitai/BaitaiEditDialog';
import { BaitaiSearchParam, BaitaiDataSet } from "../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';

const mdTheme = createTheme();

interface State {
  area: string;
  location: string;
  jusho: string;
  is_visible: boolean;
}

/**
 * 媒体管理画面コンポーネント
 *
 * @return {*} 
 */
export const Baitai = () => {
  const reload = useContext(ReloadContext);
  const [searchParam, setSearchParam] = useState<BaitaiSearchParam | null>(null);
  const [selectedRow, setSelectedRow] = useState<BaitaiDataSet | null>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [loadDownload, setLoadDownload] = useState<boolean>(false);

  const [values, setValues] = useState<State>({
    area: '',
    location: '',
    jusho: '',
    is_visible: false,
  });
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeChecked = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  // STBコンフィグ生成ボタン押下処理
  const handleDownloadSTBConfig = async () => {
    // バリデーションチェック
    if (!selectedRow) {
      return reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "媒体を選択してください。",
      });
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoadDownload(true);

    // 媒体情報取得
    const res = await postSTBConfigDownload(selectedRow.id);
    if (res?.isError) {
      await reload?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: res?.message,
      });
    }

    // zip作成処理
    const zip = new JSZip();
    for (const data of res.data) {
      // 媒体詳細IDフォルダの下にyamlファイル作成
      zip.file(`${data.baitai_shosai_id}/config_settopbox.yaml`, data.yamlString);
    }

    // Zipダウンロード
    const zipBuffer = await zip.generateAsync({ type: 'blob' });
    const url = window.URL.createObjectURL(zipBuffer);
    const a = document.createElement('a');
    a.href = url;
    a.download = `config_settopbox_${selectedRow.baitai_mei}.zip`;
    a.click();
    a.remove()

    setLoadDownload(false);
  }

  // 検索実行
  const handleSearch = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const searchParam: BaitaiSearchParam = {
      area: values.area,
      location: values.location,
      jusho: values.jusho,
      is_visible: values.is_visible
    };
    await setSearchParam(searchParam);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 0, mb: 4 }}>
            <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between" }}>
              <BaitaiEditDialog baitaiId={0} />
              <LoadingButton
                loading={loadDownload}
                type="button"
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                onClick={handleDownloadSTBConfig}
              >
                STBコンフィグファイル出力
              </LoadingButton>
            </Box>

            <Grid container spacing={1}>
              {/* 検索 */}
              <Grid item xs={3} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <Box>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="area"
                      label="エリア"
                      name="area"
                      onChange={handleChange('area')}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="location"
                      label="設置場所"
                      name="location"
                      onChange={handleChange('location')}
                      sx={{ marginTop: 1 }}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="jusho"
                      label="住所"
                      name="jusho"
                      onChange={handleChange('jusho')}
                      sx={{ marginTop: 1 }}
                    />

                    <FormGroup>
                      <FormControlLabel control={<Switch id="is_visble" onChange={handleChangeChecked('is_visible')} />} label="非表示データを表示する" />
                    </FormGroup>

                    <LoadingButton
                      loading={load}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </LoadingButton>
                  </Box>
                </Paper>
              </Grid>
              {/* 一覧 */}
              <Grid item xs={9} md={8} lg={9} pl={0}>
                {/* 一覧 */}
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <BaitaiGrid searchParam={searchParam} setSelectedRow={setSelectedRow} setload={setLoad} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}