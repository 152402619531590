import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  jaJP,
} from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import moment from "moment";

import { AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";
import { renderCellExpand } from "../Common/GridCellExpand";
import { postSearchBangumihyo, postSearchBangumihyoKanri } from "../../api/bangumihyo";
import { KENGEN, BANGUMIHYO_STATUS } from "../../const/index";
import { BangumihyoData, BangumihyoDataSet, BangumihyoSearchParam, BaitaiDataSet, BaitaiShosaiDataSet } from "../../types/WebData";
import DeleteDialog from "../Common/DeleteDialog";

/**
 * DataGrid用にデータを編集
 *
 * @param {BangumihyoDataSet[]} bangumihyoDatas
 * @return {*} 
 */
const makeGridData = (bangumihyoDatas: BangumihyoDataSet[]) => {
  return bangumihyoDatas.map((bangumihyo) => {
    // 日付フォーマット
    const haishinKaishibi = moment(bangumihyo.haishin_kaishibi);
    const updateDt = moment(bangumihyo.update_dt);
    return {
      ...bangumihyo,
      haishin_kaishibi: haishinKaishibi.format("YYYY/MM/DD"),
      update_dt: updateDt.format("YYYY/MM/DD"),
    };
  });
};

/**
 * 全件データを１ページ分の行にsliceしている
 * 値に単位をつけるなどのデータの編集も、ここで１ページ分のみ行う
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: BangumihyoDataSet[]
): Promise<BangumihyoDataSet[]> =>
  new Promise<BangumihyoDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceした後に、データを編集するfunctionに渡している
      makeGridData(allRows.slice(page * pageSize, (page + 1) * pageSize))
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  const [data, setData] = useState<BangumihyoDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  baitai: BaitaiDataSet | null,
  baitaiShosai: BaitaiShosaiDataSet | null,
  searchParam: BangumihyoSearchParam | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<BangumihyoDataSet | null>>;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 番組表管理一覧コンポーネント
 *
 * @param {Props} { baitai, searchParam, setSelectedRow }
 * @return {*} 
 */
const BangumihyoGrid = ({ baitai, baitaiShosai, searchParam, setSelectedRow, setload }: Props) => {
  const navigate = useNavigate();
  const [bangumihyoData, setBangumihyoData] = useState<BangumihyoData>({ data: [] });
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [hasBaitaiKanriKengen, setHasBaitaiKanriKengen] = useState<boolean>(false);

  // 初期表示処理
  useEffect(() => {
    if (!searchParam) return;

    const hasBaitaiKanriKengen = authInfo?.kengen === KENGEN.ADMIN || authInfo?.kengen === KENGEN.OMAKASE || (authInfo?.kaisha_id === baitaiShosai?.kaisha_id && !baitaiShosai?.omakase_pack_flg);
    setHasBaitaiKanriKengen(hasBaitaiKanriKengen);

    // 検索ボタン押下時
    const getSearchData = async () => {
      // システム管理者以外 かつ 選択中の媒体詳細の担当会社!=ログインユーザーの所属会社の場合、
      // 番組表ステータスが0:作成中は取得しない
      const result =
        hasBaitaiKanriKengen ?
          await postSearchBangumihyoKanri(searchParam)
          :
          await postSearchBangumihyo(searchParam);
      setload(false)
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const resultFormat = result.data.data.map((data: BangumihyoDataSet) => {
        const contentsData = data.contents.map((content) => {
          // 日付フォーマット
          const haishinKaishibi = moment(content.haishin_kaishibi);
          const haishinShuryobi = moment(content.haishin_shuryobi);
          return {
            ...content,
            haishin_kaishibi: haishinKaishibi.format("YYYY/MM/DD"),
            haishin_shuryobi: haishinShuryobi.format("YYYY/MM/DD"),
          };
        })
        return {
          ...data,
          contents: contentsData
        }
      });
      setBangumihyoData({ data: resultFormat });
    };

    getSearchData();
  }, [searchParam, reloadContext?.reload]);

  // 媒体または媒体詳細を変更したらレコードをクリアする
  useEffect(() => {
    setBangumihyoData({ data: [] });
  }, [baitai, baitaiShosai]);

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    bangumihyoData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const createBiko = (row: BangumihyoDataSet) => {
    let biko = "";
    if ((row.status === BANGUMIHYO_STATUS.SAKUSEIZUMI || row.status === BANGUMIHYO_STATUS.HAISHIN_YOTEI || row.status === BANGUMIHYO_STATUS.HAISHINCHU)) {
      if (row.hoeimoshikomi_henko_flg) {
        biko = "申込内容に変更があります。番組表詳細を確認してください。";
      } else if (row.bangumihyo_henko_flg) {
        biko = "番組表を保存した後、配信ボタンが押されていません。";
      }
    }
    return biko;
  }

  const columns: GridColDef[] =
    [
      {
        field: "haishin_kaishibi",
        headerName: "放映開始日",
        width: 120,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "status",
        headerName: "状態",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Typography component="h3" variant="subtitle2" sx={{ textAlign: "center" }}>
            {params.row.status === BANGUMIHYO_STATUS.SAKUSEICHU ?
              "作成中"
              : params.row.status === BANGUMIHYO_STATUS.SAKUSEIZUMI ?
                "作成済み"
                : params.row.status === BANGUMIHYO_STATUS.HAISHIN_YOTEI ?
                  "配信済"
                  : params.row.status === BANGUMIHYO_STATUS.HAISHINCHU ?
                    "放映中"
                    : ""
            }
          </Typography>
        }
      },
      {
        field: "update_dt",
        headerName: "更新日",
        width: 120,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'hoeimoshikomi_henko_flg',
        headerName: "変更有",
        headerAlign: "center",
        width: 70,
        align: "center",
        sortable: false,
        hide: !hasBaitaiKanriKengen,
        disableColumnMenu: true,
        disableExport: true,
        filterable: false,
        type: "boolean",
      },
      {
        field: 'datailButton',
        headerName: "",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        hide: !hasBaitaiKanriKengen,
        renderCell: (params) => {
          const handleClickDatail = (haishin_kaishibi: string) => {
            // 画面遷移
            navigate("/bangumihyoShosai",
              {
                state:
                {
                  baitai_id: searchParam?.baitai_id,
                  baitai_mei: baitai?.baitai_mei,
                  hoei_kaishi_jikan: baitai?.hoei_kaishi_jikan,
                  hoei_shuryo_jikan: baitai?.hoei_shuryo_jikan,
                  baitai_shosai_id: params.row.baitai_shosai_id,
                  gamen_mei: baitaiShosai?.gamen_mei,
                  size: baitaiShosai?.size,
                  bangumihyo_id: params.row.id,
                  haishin_kaishibi: haishin_kaishibi,
                  status: params.row.status,
                  searchParam
                }
              });
          };
          return <Button
            type="button"
            fullWidth
            variant="contained"
            onClick={() => handleClickDatail(params.row.haishin_kaishibi)}
          >
            詳細
          </Button>
        }
      },
      {
        field: "deleteButton",
        headerName: "",
        width: 70,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        hide: !hasBaitaiKanriKengen,
        renderCell: (params) => (
          <DeleteDialog deleteId={params.row.id} tableName="bangumihyo" params={params.row} />
        ),
      },
      {
        field: "biko",
        headerName: "備考",
        minWidth: 100,
        flex: 1,
        cellClassName: "error-message",
        sortable: false,
        disableColumnMenu: true,
        hide: !hasBaitaiKanriKengen,
        renderCell: (params) => (
          renderCellExpand(
            {
              ...params,
              value: createBiko(params.row)
            }
          )
        )
      },
      {
        field: 'blank',
        headerName: "",
        minWidth: 100,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        hide: hasBaitaiKanriKengen,
      },
    ];

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.haishin_kaishibi}
        rowCount={rowCountState}
        pagination
        paginationMode="server"
        rowsPerPageOptions={[10]}
        {...rowsState}
        sortModel={[{ field: "haishin_kaishibi", sort: "asc" }]}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          const bangumihyo = data.find((bangumihyo) => bangumihyo.haishin_kaishibi === selectionModel[0]);
          if (!bangumihyo) return;
          setSelectedRow(bangumihyo);
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};

export default BangumihyoGrid;