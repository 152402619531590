import API from "./api";
import { CancelTokenSource } from "axios";
import { ContentUploadPostData } from "../types/WebData";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * コンテンツアップロード
 *
 * @param {ContentUploadPostData} contentUploadPostData コンテンツデータ（ファイル自体は含まない）
 * @return {*} 
 */
export const putContentUpload = async (contentUploadPostData: ContentUploadPostData): Promise<any> => {
  const result = await API.put(`/cms-api/content/upload`, contentUploadPostData)
    .then((res) => {
      return {
        isError: false,
        message: "",
        newContentId: res.data.newContentId,
        signedUrlContent: res.data.signedUrlContent,
        signedUrlThumbnail: res.data.signedUrlThumbnail,
        haishinBangumihyoCount: res.data.haishinBangumihyoCount,
      };
    })
    .catch((error) => {
      const status = error.response.status;
      const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
      return { isError: true, status: status, message: message };
    });
  return result;
};

/**
 * コンテンツアップロードステータス更新
 *
 * @param {number} contentId
 * @param {number} uploadStatus
 * @return {*} 
 */
export const putContentUploadStatus = async (contentId: number, uploadStatus: number) => {
  return await API.put(`/cms-api/content/upload/status/id`, { id: contentId, upload_status: uploadStatus });
};

/**
 * コンテンツダウンロード
 *
 * @param {CancelTokenSource | null} source
 * @param {number} id
 * @param {string} filepath
 * @param {number} kengen
 * @return {*} 
 */
export const getContentDownload = async (source: CancelTokenSource | null, id: number, filepath: string, kengen?: number) => {
  return await API.get(`/cms-api/content/download`, { params: { id, filepath, kengen }, cancelToken: source?.token, responseType: 'arraybuffer' });
};

/**
 * コンテンツ情報取得
 *
 * @param {number} contentId
 * @return {*} 
 */
export const getContent = async (contentId: number) => {
  return await API.get(`/cms-api/content/id`, { params: { id: contentId } });
};
