import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import moment from 'moment';

import { ReloadContext } from "../../context/ReloadContext";
import { getBaitaiId } from "../../api/baitai";

type Props = {
  time: string | null;
  setTime: React.Dispatch<React.SetStateAction<string | null>>;
  baitaiId?: number;
  label?: string;
  disabled?: boolean;
};

/**
 * 放映時間セレクトボックスコンポーネント
 *
 * @param {Props} { time, setTime, label, disabled = false }
 * @return {*} 
 */
const HoueiHourSelect = ({ time, setTime, baitaiId, label = "放映時間 *", disabled = false }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [selectboxData, setSelectData] = useState<string[]>([]);

  // 初期化処理
  useEffect(() => {
    let isMounted = true;
    setSelectData([]);
    const getHourData = async () => {
      const id: number = baitaiId ? baitaiId : 0;
      const baitai = await getBaitaiId(id);
      if (baitai.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: baitai.message,
        });
      }

      // セレクトボックス内容の生成
      let selectbox: any = [];
      if (baitai.data[0] && baitai.data[0].hoei_kaishi_jikan && baitai.data[0].hoei_shuryo_jikan) {
        let loopCount: Number;
        const baitaiHoeiKaishiJikan = +moment(baitai.data[0].hoei_kaishi_jikan, 'HH:mm').format('H');
        const baitaiHoeiShuryoJikan = +moment(baitai.data[0].hoei_shuryo_jikan, 'HH:mm').format('H');
        if (baitaiHoeiKaishiJikan === baitaiHoeiShuryoJikan) {
          loopCount = 23;
        } else if (baitaiHoeiShuryoJikan < baitaiHoeiKaishiJikan) {
          loopCount = (baitaiHoeiShuryoJikan + 24) - baitaiHoeiKaishiJikan;
        } else {
          loopCount = baitaiHoeiShuryoJikan - baitaiHoeiKaishiJikan;
        }

        let hour = baitaiHoeiKaishiJikan;
        for (let i = 0; i <= loopCount; i++) {
          selectbox.push(hour + ":" + moment(baitai.data[0].hoei_kaishi_jikan, 'HH:mm').format('mm'))
          if (hour === 24) {
            hour = 1;
          } else {
            hour++;
          }
        }
      }

      selectbox.unshift(" ");
      setSelectData(selectbox);
    };
    if (isMounted) getHourData();
    return () => { isMounted = false };
  }, [baitaiId]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectbox = selectboxData.find(selectbox => event.target.value === String(selectbox))
    if (!selectbox) return;
    setTime(selectbox === " " ? null : selectbox);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(time) ? time : " "}
          label={label}
          onChange={handleChange}
          disabled={disabled}
          fullWidth
        >
          {selectboxData.map((selectboxData) => {
            return (
              <MenuItem key={selectboxData} value={String(selectboxData)} sx={{ height: "36px" }}>
                {selectboxData}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default HoueiHourSelect;