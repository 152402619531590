import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";
import { ReloadContext } from "../../context/ReloadContext";

export interface SnackbarInterface {
  isOpen: boolean;
  type: AlertColor | undefined;
  message: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * スナックバーコンポーネント
 *
 * @return {*} 
 */
const SimpleSnackbars = () => {
  const state = React.useContext(ReloadContext);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    state?.setSnackbarInfo({ isOpen: false, type: undefined, message: "" });
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={state?.snackbarInfo.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        sx={{ height: 'auto', lineHeight: '28px', whiteSpace: 'pre-line' }}
      >
        <Alert
          onClose={handleClose}
          severity={state?.snackbarInfo.type}
          sx={{ width: "100%" }}
        >
          {state?.snackbarInfo.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default SimpleSnackbars;
