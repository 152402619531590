import Typography from '@mui/material/Typography';

/**
 * コピーライトコンポーネント
 * リンククリックでCMNホームページを開く。
 *
 * @return {*} 
 */
export const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 4, mb: 4 }}>
            {'Copyright © CMN Co.,Ltd. '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}