import React, { useState, useContext, useEffect } from "react";

import {
  DataGrid,
  GridColDef,
  jaJP,
} from "@mui/x-data-grid";

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { renderCellExpand } from "../Common/GridCellExpand";
import { KaizodoEditDialog } from './KaizodoEditDialog'
import ToggleVisibilityDialog from '../Common/ToggleVisibilityDialog'
import {
  KaizodoData, KaizodoDataSet, KaizodoSearchParam
} from "../../types/WebData";
import { postSearchKaizodo } from "../../api/kaizodo";

/**
 * DataGrid用にデータを編集
 *
 * @param {KaizodoDataSet[]} kaizodoDatas
 * @return {*} 
 */
const makeGridData = (kaizodoDatas: KaizodoDataSet[]) => {
  return kaizodoDatas.map((kaizodoData) => {
    // 日付フォーマット
    const updateDate = moment(kaizodoData.update_dt);
    return {
      ...kaizodoData,
      update_dt: updateDate.format("YYYY/MM/DD"),
    };
  });
};

/**
 * 全件データを１ページ分の行にsliceしている
 * 値に単位をつけるなどのデータの編集も、ここで１ページ分のみ行う
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: KaizodoDataSet[]
): Promise<KaizodoDataSet[]> =>
  new Promise<KaizodoDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceした後に、データを編集するfunctionに渡している
      makeGridData(allRows.slice(page * pageSize, (page + 1) * pageSize))
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = React.useState<number | undefined>(undefined);
  const [data, setData] = React.useState<KaizodoDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  searchParam: KaizodoSearchParam | null;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 解像度設定一覧コンポーネント
 *
 * @return {searchParam} 
 */
export const KaizodoGrid = ({ searchParam, setload }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [kaizodoData, setKaizodoData] = useState<KaizodoData>({ data: [] });

  useEffect(() => {
    const getSearchData = async (param: KaizodoSearchParam) => {
      const result = await postSearchKaizodo(param);
      setKaizodoData(result);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setload(false)
    };

    if (!searchParam) {
      getSearchData({ kaizodo: "", is_visible: false })
    } else {
      getSearchData(searchParam);
    }
  }, [searchParam, reloadContext?.reload]);

  const columns: GridColDef[] =
    [
      {
        field: "id",
        headerName: "ID",
        hide: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "kaizodo",
        headerName: "解像度",
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "update_dt",
        headerName: "更新日",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'editButton',
        headerName: "",
        width: 20,
        align: "center",
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: (params) => (
          <KaizodoEditDialog
            KaizodoId={params.row.id} />
        )
      },
      {
        field: "toggleVisibilityDialog",
        headerName: "",
        width: 20,
        align: "center",
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: (params) => (
          <ToggleVisibilityDialog deleteId={params.row.id} isVisible={!params.row.del_flg} tableName="kaizodo" />
        ),
      },
    ];

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    kaizodoData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        {...rowsState}
        rowsPerPageOptions={[10]}
        pagination
        paginationMode="server"
        sortModel={[{ field: "id", sort: "asc" }]}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};