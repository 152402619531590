import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Divider,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import styleCss from "./BaseLayout.module.css";

import { AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";

import ActiveLink from "./ActiveLink";
import MuiIcon from "./MuiIcon";
import { MenuDataSet } from "../../types/WebData";
import { MENU_TYPE } from "../../const/index";

/**
 * サイドメニューコンポーネント
 *
 * @return {*} 
 */
const SideMenu = () => {
  const navigate = useNavigate();
  const [authInfo] = useContext(AuthInfoContext);
  const reload = useContext(ReloadContext);

  const onClickMenu = (contentPath: string) => {
    // アップロード、ダウンロード中ではない場合、そのまま画面遷移する
    if (!reload?.uploadingInfo.isUploading && !reload?.downloadingInfo.isDownloading) return navigate(contentPath);

    // アップロード中の場合
    if (reload?.uploadingInfo.isUploading) {
      let confirmResult = true;
      confirmResult = window.confirm("このサイトを離れますか？\n行った変更が保存されない可能性があります。");
      if (confirmResult) {
        // アップロードキャンセル
        reload?.uploadingInfo.source?.cancel();
        navigate(contentPath);
      }
    }

    // ダウンロード中の場合
    if (reload?.downloadingInfo.isDownloading) {
      // ダウンロードキャンセル
      reload?.downloadingInfo.source?.cancel();
      navigate(contentPath);
    }
  }

  return (
    <React.Fragment>
      <List>
        <ListSubheader inset>業務メニュー</ListSubheader>
        {reload?.menuData.data.map((menu: MenuDataSet) => {
          if (
            menu.kengen_id === authInfo?.kengen &&
            menu.riyo_flg &&
            menu.menu_type === MENU_TYPE.GYOMU
          ) {
            return (
              <ActiveLink
                className={`${styleCss.menu} ${menu.isCurrent ? styleCss.menuActive : ""}`}
                key={menu.id}
                onClick={() => onClickMenu(menu.contents_path)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MuiIcon iconName={menu.icon} />
                  </ListItemIcon>
                  <ListItemText primary={menu.menu_name} />
                </ListItemButton>
              </ActiveLink>
            );
          } else {
            return null;
          }
        })}
      </List>
      <div>
        <Divider />
        <List>
          <ListSubheader inset>設定</ListSubheader>
          {reload?.menuData.data.map((menu: MenuDataSet) => {
            if (
              menu.kengen_id === authInfo?.kengen &&
              menu.riyo_flg &&
              menu.menu_type === MENU_TYPE.SETTING
            ) {
              return (
                <ActiveLink
                  className={`${styleCss.menu} ${menu.isCurrent ? styleCss.menuActive : ""}`}
                  key={menu.id}
                  onClick={() => onClickMenu(menu.contents_path)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <MuiIcon iconName={menu.icon} />
                    </ListItemIcon>
                    <ListItemText primary={menu.menu_name} />
                  </ListItemButton>
                </ActiveLink>
              );
            } else {
              return null;
            }
          })}
        </List>
      </div>
    </React.Fragment >
  );
}

export default SideMenu;