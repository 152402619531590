import React from "react";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja'
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

type Props = {
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  label: string;
  disabled?: boolean;
  minDate?: Date;
};

/**
 * 単一DatePickerコンポーネント
 *
 * @param {Props} { date, setDate, label, disabled = false, minDate }
 * @return {*} 
 */
const SimpleDatePicker = ({ date, setDate, label, disabled = false, minDate }: Props) => {
  return (
    <Box sx={{ mt: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <DatePicker
          mask="____/__/__"
          label={label}
          value={date}
          inputFormat={"yyyy/MM/dd"}
          onChange={(newValue: Date | null) => {
            setDate(newValue || null);
          }}
          renderInput={(params: any) => (
            <TextField {...params} fullWidth />
          )}
          disabled={disabled}
          minDate={minDate}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default SimpleDatePicker;
