import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { KengenData } from "../../types/WebData";
import { getKengen } from '../../api/kengen';
import { ReloadContext } from "../../context/ReloadContext";

type Props = {
  kengenId: number;
  setKengenId: React.Dispatch<React.SetStateAction<number>>;
  label?: string;
  disabled?: boolean;
};

/**
 * 権限セレクトボックスコンポーネント
 *
 * @param {Props} { kengenId, setKengenId, label, disabled = false }
 * @return {*} 
 */
const KengenSelect = ({ kengenId, setKengenId, label = "権限", disabled = false }: Props) => {
  const [kengenData, setKengenData] = useState<KengenData>({ data: [] });
  const reloadContext = useContext(ReloadContext);

  // 初期化処理
  useEffect(() => {
    const getData = async () => {
      const result = await getKengen();
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setKengenData(result);
    };
    getData();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setKengenId(+event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={String(kengenId)}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {kengenData.data.map((kengen) => {
            return (
              <MenuItem key={kengen.id} value={kengen.id}>
                {kengen.kengen_mei}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default KengenSelect;