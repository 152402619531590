import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Item, GroupType, GroupTypes } from '../../Common/Preview/data';
import { BangumihyoShosaiData } from "../../../types/WebData";
import moment from 'moment';

type GroupedItems = {
  [k in GroupType]?: Item[]
};
type UseGroupedItems = (bangumihyoShosaiData: BangumihyoShosaiData) => [GroupedItems, Item[], Dispatch<SetStateAction<Item[]>>];

const useGroupedItems: UseGroupedItems = (bangumihyoShosaiData) => {
  const [list, setList] = useState<Item[]>([]);
  const [groupedItems, setGroupedItems] = useState<GroupedItems>({});

  useEffect(() => {
    const items: Item[] = bangumihyoShosaiData.data.map(d => {
      return {
        id: d.index,
        type: 'thumbnail',
        group: (d.saisei_seq) ? "bangumihyo" : "content",
        content: {
          id: d.id,
          bangumihyo_id: d.bangumihyo_id,
          hoeimoshikomi_id: d.hoeimoshikomi_id,
          bangumi_mei: d.bangumi_mei,
          kokyaku_mei: d.kokyaku_mei,
          kaisha_mei: d.kaisha_mei,
          content_file_mei: d.content_file_mei,
          content_file_path: d.content_file_path,
          content_file_extension: d.content_file_extension,
          thumbnail_file_path: d.thumbnail_file_path,
          content_byosu: d.content_byosu,
          haishin_kaishibi: moment(d.haishin_kaishibi).format('YYYY/MM/DD'),
          haishin_shuryobi: (d.haishin_shuryobi) ? moment(d.haishin_shuryobi).format('YYYY/MM/DD') : "",
          haishin_kaishijikan: d.haishin_kaishijikan,
          haishin_shuryojikan: d.haishin_shuryojikan,
          loginuser_id: d.loginuser_id,
          kaisha_id: d.kaisha_id
        }
      }
    })
    setList(items);
  }, [bangumihyoShosaiData])

  useEffect(() => {
    setGroupedItems(
      GroupTypes.reduce<GroupedItems>((acc, group) => {
        acc[group] = list.filter(v => v.group === group);
        return acc;
      }, {})
    );
  }, [list])
  return [groupedItems, list, setList];
};

export default useGroupedItems;