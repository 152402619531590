import { Children, cloneElement } from 'react'
import Box from "@mui/material/Box";

/**
 * メニューボタンコンポーネント
 * サイドメニューに表示するメニューの数だけ呼び出します。
 *
 * @param {any} { children, ...props }
 * @return {*} 
 */
const ActiveLink = ({ children, ...props }: any) => {
  const child = Children.only(children)
  const className = props.className || ''
  return <Box {...props}>{cloneElement(child, { className })}</Box>
}

export default ActiveLink