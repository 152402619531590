import { useEffect, useState } from "react";

import { getThumbnail } from "../../api/bangumihyoShosai";


type Props = {
  src: string;
  height?: string;
  width?: string;
};

/**
 * サムネイルコンポーネント
 *
 * @param {*}
 * @return {*} 
 */
const Thumbnail = ({ src, height = '150px', width = '220px' }: Props) => {
  const [thumbnail, setThumbnail] = useState<string>("");
  const [isNoImage, setIsNoImage] = useState<boolean>(false);

  useEffect(() => {
    if (src) {
      const getThumbnailWrap = async () => {
        const result = await getThumbnail(src);
        setThumbnail(result.data);
      };

      getThumbnailWrap();
    } else {
      // サムネイルが無い場合、デフォルトのNoImage画像を表示
      const thumbnailUrl = `${process.env.PUBLIC_URL}/NoImage.png`;
      setThumbnail(thumbnailUrl);
      setIsNoImage(true);
    }
  }, [src])

  return <div style={{
    minHeight: height,
    maxHeight: height,
    minWidth: width,
    maxWidth: width,
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  }}>
    <img src={thumbnail} alt="Thumbnail" style={{
      width: '100%',
      height: '100%',
      objectFit: isNoImage ? 'fill' : 'contain',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    }} />
  </div>
}

export default Thumbnail;