import { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Edit from '@mui/icons-material/Edit';

import moment from "moment";

import KikiShubetsuSelect from '../Common/KikiShubetsuSelect';
import KikiHimozukesakiCodeSelect from '../Common/KikiHimozukesakiCodeSelect';
import { ReloadContext } from "../../context/ReloadContext";
import { KikiShubetsuDataSet } from "../../types/WebData";
import { getKikiKatabanId, updateKikiKataban } from "../../api/kikiKataban";
import { KikiHimozukesakiCode } from "../../const/index";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  kikiKatabanId: number;
}

/**
 * 機器型番ダイアログコンポーネント
 *
 * @param {Props} { kikiKatabanId }
 * @return {*} 
 */
const KikiKatabanEditDialog = ({ kikiKatabanId }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();

  const [kikiShubetsu, setKikiShubetsu] = useState<KikiShubetsuDataSet | null>(null);
  const [seihinMei, setSeihinMei] = useState<string>("");
  const [kataban, setKataban] = useState<string>("");
  const [kikiHimozukesakiCode, setKikiHimozukesakiCode] = useState<KikiHimozukesakiCode | null>(null);
  const [update_dt, setUpdateDt] = useState<Date | null>(null);

  // エラーメッセージ表示用
  // 各入力項目ごとのメッセージ
  const [errorMessageKikiShubetsu, setErrorMessageKikiShubetsu] = useState<string>("");
  const [errorMessageKataban, setErrorMessageKataban] = useState<string>("");
  const [errorMessageSeihinMei, setErrorMessageSeihinMei] = useState<string>("");
  const [errorMessageKikiHimozukesakiCode, setErrorMessageKikiHimozukesakiCode] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);

  const isCreate = kikiKatabanId === 0;
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (isCreate) { // 新規登録
      setKikiShubetsu(null);
      setSeihinMei("");
      setKataban("");
      setKikiHimozukesakiCode(null);
      setUpdateDt(null);
    } else {
      // 機器型番情報取得
      const res = await getKikiKatabanId(kikiKatabanId);
      if (res?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }
      setKikiShubetsu({ code: res.kiki_shubetsu_code });
      setSeihinMei(res.seihin_mei);
      setKataban(res.kataban);
      setKikiHimozukesakiCode({ code: res.kiki_himozukesaki_code, name: "" });
      setUpdateDt(res.update_dt);
    }

    setOpen(true);
  };

  /**
 * エラーメッセージ初期化処理
 */
  const initErrorMessage = () => {
    setErrorMessageKikiShubetsu("")
    setErrorMessageKataban("")
    setErrorMessageSeihinMei("")
    setErrorMessageKikiHimozukesakiCode("")
  }

  const handleClose = () => {
    setOpen(false);
  };

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // バリデーションチェック
    setErrorMessageKikiShubetsu("")
    setErrorMessageKataban("")
    setErrorMessageSeihinMei("")
    setErrorMessageKikiHimozukesakiCode("")

    if (!kikiShubetsu || kikiShubetsu.code === 0) {
      return setErrorMessageKikiShubetsu("機器種別を選択してください。");
    }
    if (seihinMei === "") {
      return setErrorMessageSeihinMei("製品名を入力してください。");
    }
    if (kataban === "") {
      return setErrorMessageKataban("型番を入力してください。");
    }
    if (!kikiHimozukesakiCode) {
      return setErrorMessageKikiHimozukesakiCode("機器紐づけ先を選択してください。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setLoad(true);

    const result = await updateKikiKataban({
      id: kikiKatabanId,
      kiki_shubetsu_code: kikiShubetsu.code,
      seihin_mei: seihinMei,
      kataban: kataban,
      kiki_himozukesaki_code: kikiHimozukesakiCode.code,
      update_dt: (update_dt) ? moment(update_dt).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });

    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    setLoad(false);
    await reloadSetting();
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規機器型番登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h6" mt={1} ml={2}>
            {isCreate ? "新規機器型番登録" : "機器型番情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0 }}>
            <KikiShubetsuSelect kikiShubetsu={kikiShubetsu} setKikiShubetsu={setKikiShubetsu} label={"機器種別 *"} />
            {errorMessageKikiShubetsu && (
              <small className={"error-message"}>{errorMessageKikiShubetsu}</small>
            )}
            <TextField
              label="製品名 *"
              value={seihinMei}
              onChange={e => setSeihinMei(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageSeihinMei && (
              <small className={"error-message"}>{errorMessageSeihinMei}</small>
            )}
            <TextField
              label="機器型番 *"
              value={kataban}
              onChange={e => setKataban(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageKataban && (
              <small className={"error-message"}>{errorMessageKataban}</small>
            )}
            <KikiHimozukesakiCodeSelect
              kikiHimozukesakiCode={kikiHimozukesakiCode}
              setKikiHimozukesakiCode={setKikiHimozukesakiCode}
              label="機器紐づけ先 *"
            />
            {errorMessageKikiHimozukesakiCode && (
              <small className={"error-message"}>{errorMessageKikiHimozukesakiCode}</small>
            )}

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <LoadingButton loading={load} variant="contained" type="submit">登録</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default KikiKatabanEditDialog;