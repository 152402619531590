import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";
import { postSearchBaitaiShosai } from "../../api/baitaiShosai";
import { BaitaiShosaiData, BaitaiShosaiDataSet } from "../../types/WebData";

type Props = {
  baitaiId: number | undefined;
  baitaiShosai: BaitaiShosaiDataSet | null;
  setBaitaiShosai: React.Dispatch<React.SetStateAction<BaitaiShosaiDataSet | null>>;
  label?: string;
  disabled?: boolean;
  kanri_only_flg?: boolean;
};

/**
 * 媒体詳細セレクトボックスコンポーネント
 *
 * @param {Props} { baitaiId, baitaiShosai, setBaitaiShosai, label, disabled = false }
 * @return {*} 
 */
const BaitaiShosaiSelect = ({ baitaiId, baitaiShosai, setBaitaiShosai, label = "画面名", disabled = false, kanri_only_flg }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [baitaiData, setBaitaiData] = useState<BaitaiShosaiData>({ data: [] });

  // 初期化処理
  useEffect(() => {
    if (!baitaiId) {
      setBaitaiData({ data: [{ id: 0 }] });
      return;
    }
    let isMounted = true;
    const getBaitaiData = async () => {
      const searchParam = { baitai_id: baitaiId, kengen: authInfo.kengen, kaisha_id: authInfo.kaisha_id, kanri_only_flg: kanri_only_flg ? true : false };
      const result = await postSearchBaitaiShosai(searchParam);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const blank = { id: 0 } as BaitaiShosaiDataSet;
      result.data.unshift(blank);
      setBaitaiData(result);
    };
    if (isMounted) getBaitaiData();
    return () => { isMounted = false };
  }, [baitaiId]);

  const handleChange = (event: SelectChangeEvent) => {
    const baitaiShosai = baitaiData.data.find(baitaiShosai => event.target.value === String(baitaiShosai.id))
    if (!baitaiShosai) return;
    setBaitaiShosai({ ...baitaiShosai, baitai_id: baitaiId });
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(baitaiShosai) ? String(baitaiShosai.id) : ""}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {baitaiData.data.map((baitaiShosai) => {
            return (
              <MenuItem key={baitaiShosai.id} value={String(baitaiShosai.id)} sx={{ height: "36px" }}>
                {baitaiShosai.gamen_mei}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default BaitaiShosaiSelect;