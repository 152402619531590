import { useState, useContext, useRef } from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Delete from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import { deleteHoeimoshikomi } from "../../api/hoeimoshikomi";
import { deleteBangumihyo } from "../../api/bangumihyo";
import { deleteJack } from "../../api/jack";
import { BANGUMIHYO_STATUS } from "../../const/index";
import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  baitaiKanriKaishaId?: number;
  moshikomiKaishaId?: number;
  omakasePackFlg?: boolean;
  deleteId: number;
  tableName: string;
  params?: any;
};

/**
 * 削除確認ダイアログコンポーネント
 *
 * @param {Props} { deleteId, tableName, delFlg, params }
 * @return {*} 
 */
const DeleteDialog = ({ baitaiKanriKaishaId, moshikomiKaishaId, omakasePackFlg, deleteId, tableName, params }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [authInfo] = useContext(AuthInfoContext);
  const [open, setOpen] = useState(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handleClickOpen = () => {
    // 放映申込の削除期限を過ぎている場合、削除不可
    if (params && params.snackbar_info) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: params.snackbar_info.type,
        message: params.snackbar_info.message,
      });
    }
    // 放映中の番組表は削除不可
    if (params && params.status === BANGUMIHYO_STATUS.HAISHINCHU) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: "放映中の番組表は削除できません。",
      });
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRow = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    // 各テーブルdelete api
    const deleteApi = (tableName: string) => {
      switch (tableName) {
        case "hoeimoshikomi":
          return deleteHoeimoshikomi(deleteId);
        case "bangumihyo":
          return deleteBangumihyo(deleteId);
        case "jack":
          return deleteJack(deleteId);
        default:
          break;
      }
    };
    const result = await deleteApi(tableName);
    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result?.isError ? "error" : "success",
      message: (!result?.isError) ? "削除しました。" : result.message,
    });
    await reloadSetting();
    setLoad(false);
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        disabled={authInfo.kaisha_id === baitaiKanriKaishaId ? omakasePackFlg && authInfo.kaisha_id !== moshikomiKaishaId ? true : false : false}
        color="inherit"
      >
        <Delete color={authInfo.kaisha_id === baitaiKanriKaishaId ? omakasePackFlg && authInfo.kaisha_id !== moshikomiKaishaId ? "disabled" : "primary" : "primary"} fontSize='large'
          onClick={() => handleClickOpen()}
        />
      </IconButton>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "20vw",
          }
        }}
      >
        <Typography component="h3" variant="subtitle1" mt={2} ml={2}>
          削除確認
        </Typography>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={deleteRow}>削除する</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default DeleteDialog;