import { useState, useRef } from "react";

import { useNavigate, useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {
  Box,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";

import { Copyright } from "../components/Common/Copyright";
import PlayOrder from "../components/BangumihyoShosai/PlayOrder";
import SaveConfirmDialog from "../components/BangumihyoShosai/SaveConfirmDialog";
import { BANGUMIHYO_STATUS } from "../const/index";
import { BangumihyoShosaiData, BangumihyoShosaiProps } from "../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';

const mdTheme = createTheme();

/**
 * 番組表詳細画面コンポーネント
 *
 * @return {*} 
 */
export const BangumihyoShosai = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as BangumihyoShosaiProps;

  const [bangumihyoShosaiData, setBangumihyoShosaiData] = useState<BangumihyoShosaiData>({ data: [] });
  const childRef = useRef<any>(null)
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  const handlePageBack = () => {
    const confirm = window.confirm("このページを離れますか？\n行った変更が保存されない可能性があります。");
    if (confirm) {
      navigate("/bangumihyo", { state }); // 画面遷移
    }
  }

  const handleTempSave = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setLoad(true);

    await childRef?.current?.handleTempSave(setLoad);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 2, mb: 4 }}>

            {/* 一覧 */}
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                maxWidth: '80vw',
              }}
            >
              <Grid container spacing={1}>
                {/* タイトル */}
                <Grid item xs={12} pl={0}>
                  <Typography component="h1" variant="h5">
                    {`${state.gamen_mei} ${moment(state.haishin_kaishibi).format('YYYY/MM/DD')}～`}
                  </Typography>
                </Grid>
                {/* ボタンエリア */}
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="end">
                    <Button
                      type="submit"
                      variant="outlined"
                      onClick={handlePageBack}
                      sx={{ mt: 2, mb: 2 }}
                    >
                      戻る
                    </Button>
                    {
                      state.status === BANGUMIHYO_STATUS.SAKUSEICHU ?
                        <LoadingButton
                          loading={load}
                          type="submit"
                          variant="contained"
                          onClick={handleTempSave}
                          sx={{ ml: 1, mt: 2, mb: 2 }}
                        >
                          一時保存
                        </LoadingButton>
                        : ""
                    }
                    <SaveConfirmDialog childRef={childRef} />
                  </Box>
                </Grid>
              </Grid>

              <PlayOrder ref={childRef} state={state} bangumihyoShosaiData={bangumihyoShosaiData} setBangumihyoShosaiData={setBangumihyoShosaiData}></PlayOrder>
            </Paper>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}