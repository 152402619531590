import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';

import {
  Box,
  Toolbar,
} from "@mui/material";

import { Copyright } from "../components/Common/Copyright";
import KengenForm from "../components/Kengen/KengenForm";

const mdTheme = createTheme();

/**
 * 権限設定画面コンポーネント
 *
 * @return {*} 
 */
export const Kengen = () => {
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 2, mb: 4 }}>
            <KengenForm />
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
};