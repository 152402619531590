import {
  HoeimoshikomiSearchParam,
  HoeimoshikomiEditData
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * hoeimoshikomiテーブルAPI
 * 放映申込検索
 *
 * @param {HoeimoshikomiSearchParam} searchParam
 * @return {*} 
 */
export const postSearchHoeimoshikomi = async (searchParam: HoeimoshikomiSearchParam) => {
  const result = await API.post(`/cms-api/hoeimoshikomi/search`, searchParam)
    .then(
      (res) => {
        return res.data;
      }
    )
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * hoeimoshikomiテーブルAPI
 * 放映申込詳細検索
 *
 * @param {number} id
 * @return {*} 
 */
export const getHoeimoshikomiDetail = async (id: number) => {
  const result = await API.get(`/cms-api/hoeimoshikomi/id`, { params: { id } })
    .then(
      (res) => {
        return res.data;
      }
    )
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * hoeimoshikomiテーブルAPI
 * 放映申込 新規登録、更新
 *
 * @param {HoeimoshikomiEditData} postData
 * @return {*} 
 */
export const postHoeimoshikomi = async (postData: HoeimoshikomiEditData) => {
  let result = { isError: false, message: "", overMaxDays: [], status: 0 };
  if (postData.id === 0) {
    // insert
    result = await API.post("/cms-api/hoeimoshikomi", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO, overMaxDays: res.data.overMaxDays, status: HTTP_STATUS_CODE.OK };
      })
      .catch((error) => {
        return { isError: true, message: MESSAGE.SYSTEM_ERROR, overMaxDays: [], status: error.response.status };
      });
  } else {
    // update
    result = await API.put("/cms-api/hoeimoshikomi/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.KOSHIN_SEIKO, overMaxDays: res.data.overMaxDays, status: HTTP_STATUS_CODE.OK };
      })
      .catch((error) => {
        const status = error.response.status;
        const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
        return { isError: true, message: message, overMaxDays: [], status: status };
      });
  }
  return result;
};

/**
 * hoeimoshikomiテーブルAPI
 * 放映申込削除
 *
 * @param {number} id
 * @param {string} haishinKaishibi
 * @param {string} haishinShuryobi
 * @return {*} 
 */
export const deleteHoeimoshikomi = async (id: number) => {
  const result = await API.put(`/cms-api/hoeimoshikomi/delete/id`, { id: id })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};

/**
 * hoeimoshikomiテーブルAPI
 * 放映開始時間、放映終了時間検索
 *
 * @param {number} baitai_id
 * @return {*} 
 */
export const postSearchHoeimoshikomiJikan = async (baitai_id: number) => {
  const result = await API.post(`/cms-api/hoeimoshikomi/search/jikan`, { baitai_id })
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};
