import { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReloadContext } from "../../context/ReloadContext";
import { postSearchBaitaiShubetsu } from "../../api/baitaiShubetsu";
import { BaitaiShubetsuData, BaitaiShubetsuDataSet } from "../../types/WebData";

type Props = {
  baitaiShubetsu: BaitaiShubetsuDataSet | null;
  setBaitaiShubetsu: React.Dispatch<React.SetStateAction<BaitaiShubetsuDataSet | null>>;
  label?: string;
  disabled?: boolean;
};

/**
 * 媒体種別セレクトボックスコンポーネント
 *
 * @param {Props} { baitaiShubetsu, setBaitaiShubetsu, label, disabled = false }
 * @return {*} 
 */
const BaitaiShubetsuSelect = ({ baitaiShubetsu, setBaitaiShubetsu, label = "媒体種別", disabled = false }: Props) => {
  const [baitaiShubetsuData, setBaitaiShubetsuData] = useState<BaitaiShubetsuData>({ data: [] });
  const reloadContext = useContext(ReloadContext);

  // 初期化処理
  useEffect(() => {
    let isMounted = true;
    const getLocationData = async () => {
      const searchParam = { id: baitaiShubetsu?.id, shubetsu_mei: "", is_visible: false };
      const result = await postSearchBaitaiShubetsu(searchParam);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const blank = { id: 0 } as BaitaiShubetsuDataSet;
      result.data.unshift(blank);
      setBaitaiShubetsuData(result);
    };
    if (isMounted) getLocationData();
    return () => { isMounted = false };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const baitai = baitaiShubetsuData.data.find(baitaiShubetsu => event.target.value === String(baitaiShubetsu.id))
    if (!baitai) return;
    setBaitaiShubetsu(baitai);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(baitaiShubetsu) ? String(baitaiShubetsu.id) : ""}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {baitaiShubetsuData.data.map((baitaiShubetsu) => {
            return (
              <MenuItem key={baitaiShubetsu.id} value={String(baitaiShubetsu.id)} sx={{ height: "36px" }}>
                {baitaiShubetsu.shubetsu_mei}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default BaitaiShubetsuSelect;