import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ReloadContext } from "../../context/ReloadContext";
import { postSearchArea } from "../../api/area";
import { AreaData, AreaDataSet } from "../../types/WebData";

type Props = {
  area: AreaDataSet | null;
  setArea: React.Dispatch<React.SetStateAction<AreaDataSet | null>>;
  label?: string;
  disabled?: boolean;
};

/**
 * エリアセレクトボックスコンポーネント
 *
 * @param {Props} { area, setArea, label, disabled = false }
 * @return {*} 
 */
const AreaSelect = ({ area, setArea, label = "エリア *", disabled = false }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [areaData, setAreaData] = useState<AreaData>({ data: [] });

  // 初期化処理
  useEffect(() => {
    let isMounted = true;
    setAreaData({ data: [] });
    const getAreaData = async () => {
      const searchParam = { id: area?.id, area_mei: "", is_visible: false };
      const result = await postSearchArea(searchParam);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      const blank = { id: 0 } as AreaDataSet;
      result.data.unshift(blank);
      setAreaData(result);
    };
    if (isMounted) getAreaData();
    return () => { isMounted = false };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const area = areaData.data.find(area => event.target.value === String(area.id))
    if (!area) return;
    setArea(area);
  };

  return (
    <Box sx={{ minWidth: 120 }} mt={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(area) ? String(area.id) : ""}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {areaData.data.map((areaData) => {
            return (
              <MenuItem key={areaData.id} value={String(areaData.id)} sx={{ height: "36px" }}>
                {areaData.area_mei}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default AreaSelect;