import { useNavigate, useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {
  Box,
  Toolbar,
  Typography,
} from "@mui/material";

import { Copyright } from "../components/Common/Copyright";
import { JackShosaiGrid } from "../components/JackShosai/JackShosaiGrid";
import { JackShosaiProps } from "../types/WebData";

const mdTheme = createTheme();

/**
 * ジャック詳細画面コンポーネント
 *
 * @return {*} 
 */
export const JackShosai = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as JackShosaiProps;

  const handlePageBack = () => {
    navigate("/jack", { state }); // 画面遷移
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 2, mb: 4 }}>

            {/* 一覧 */}
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                maxWidth: '80vw',
              }}
            >
              <Grid container spacing={1}>
                {/* タイトル */}
                <Grid item xs={12} pl={0}>
                  <Typography component="h1" variant="h5">
                    {state.jack_mei}
                  </Typography>
                </Grid>
                {/* ボタンエリア */}
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="end">
                    <Button
                      type="submit"
                      variant="outlined"
                      onClick={handlePageBack}
                      sx={{ mt: 1, mb: 1 }}
                    >
                      戻る
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <JackShosaiGrid jackId={state.jack_id} />
            </Paper>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}