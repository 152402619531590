import { useState, useContext, useEffect } from "react";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Grid,
} from "@mui/material";

import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import { OshiraseData } from "../../types/WebData";
import OshiraseShosaiDialog from './OshiraseShosaiDialog';
import OshiraseDeleteAllDialog from './OshiraseDeleteAllDialog';
import styleCss from "../Common/BaseLayout.module.css";
import { getOshirase, getOshiraseCount } from "../../api/oshirase";
import { postSearchCodemaster } from "../../api/codemaster";
import { CodeMasterSearchParam } from "../../types/WebData";
import { CODE_MASTER_CATEGORY } from "../../const/index";

type Props = {
  countMidoku: number;
  setCountMidoku: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * お知らせダイアログコンポーネント
 *
 * @param {Props} { oshiraseData, setOshiraseData }
 * @return {*} 
 */
const OshiraseDialog = ({ countMidoku, setCountMidoku }: Props) => {
  const [oshiraseData, setOshiraseData] = useState<OshiraseData>({ data: [] });
  const reloadContext = useContext(ReloadContext);
  const [authInfo] = useContext(AuthInfoContext);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [intervalOshirase, setIntervalOshirase] = useState<number>(0);

  useEffect(() => {
    const getIntervalData = async () => {
      const searchParam: CodeMasterSearchParam = { category_id: CODE_MASTER_CATEGORY.INTERVAL_OSHIRASE }
      const result = await postSearchCodemaster(searchParam);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result?.message,
        });
      }
      setIntervalOshirase(!result && isNaN(result.data[0].code_name) ? 0 : Number(result.data[0].code_name));
    };
    getIntervalData();
  }, [])

  // 件数を取得する
  useEffect(() => {
    // 件数取得
    const getCount = async () => {
      const result = await getOshiraseCount(authInfo.id);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result?.message,
        });
      }
      setCount(Number(result.data[0].count));
    };

    // 未読件数取得
    const getMidokuCount = async () => {
      const result = await getOshiraseCount(authInfo.id, true);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result?.message,
        });
      }
      setCountMidoku(Number(result.data[0].count));
    };

    getCount();
    getMidokuCount();
  }, [reloadContext?.reload, open, time])

  // 一定間隔で実行する
  useEffect(() => {
    if (intervalOshirase !== 0) {
      const id = setInterval(() => {
        setTime(time + 1);
      }, intervalOshirase);
      return () => clearInterval(id);
    }
  }, [time, intervalOshirase])

  // お知らせを取得する
  useEffect(() => {
    if (open) {
      const getOshiraseData = async () => {
        const result = await getOshirase(authInfo.id);
        if (result?.isError) {
          await reloadContext?.setSnackbarInfo({
            isOpen: true,
            type: "error",
            message: result?.message,
          });
          return
        }
        setOshiraseData(result);
      };

      getOshiraseData();

    }
  }, [count, open, reloadContext?.reload])

  const handleClickOpen = async () => {
    if (count === 0) {
      return await reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: `warning`,
        message: `お知らせがありません。`,
      });
    };
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <MenuItem className={styleCss.acountMenu} onClick={handleClickOpen}>
        {countMidoku !== 0 ? <span className={styleCss.count}>{countMidoku}</span> : ""}お知らせ
      </MenuItem>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "80vw",
            height: "80vh",
            maxWidth: "75vw"
          }
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography component="h3" variant="h6" mt={2} mb={2} ml={2}>
              お知らせ
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography component="h3" variant="h6" mt={2} mb={2} mr={4}>
              {oshiraseData.data.length}件
            </Typography>
          </Grid>
        </Grid>
        <DialogContent>
          {
            (!oshiraseData || oshiraseData.data.length === 0)
              ? ""
              : oshiraseData?.data.map(oshirase => {
                return (
                  <Box key={oshirase.id}>
                    <OshiraseShosaiDialog oshiraseData={oshirase} />
                  </Box>
                )
              })}
        </DialogContent >
        <DialogActions>
          <OshiraseDeleteAllDialog loginuserId={authInfo.id} />
          <Button sx={{ml:1}} variant="outlined" onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog >
    </Box >
  );
}

export default OshiraseDialog;
