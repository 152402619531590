import { useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Edit from '@mui/icons-material/Edit';

import moment from "moment";

import { AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";
import { getSearchKokyakuId, updateKokyaku } from "../../api/kokyaku";
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  kokyakuId: number;
}

/**
 * 広告主設定ダイアログコンポーネント
 *
 * @param {Props} { kokyakuId }
 * @return {*} 
 */
const KokyakuEditDialog = ({ kokyakuId }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const { handleSubmit } = useForm<any>();

  const [kokyakuMei, setKokyakuMei] = useState<string>("");
  const [jusho, setJusho] = useState<string>("");
  const [loginuserId, setLoginuserId] = useState<number>(0);
  const [kaishaId, setKaishaId] = useState<number>(0);
  const [updateDt, setUpdateDt] = useState<Date | null>(null);
  const [load, setLoad] = useState<boolean>(false);

  // エラーメッセージ表示用
  // 各入力項目ごとのメッセージ
  const [errorMessageKokyakuMei, setErrorMessageKokyakuMei] = useState<string>("");
  const [errorMessageJusho, setErrorMessageJusho] = useState<string>("");

  const isCreate = kokyakuId === 0;
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickOpen = async () => {
    // エラーメッセージ初期化
    initErrorMessage();

    if (isCreate) { // 新規登録
      setKokyakuMei("");
      setJusho("");
      setLoginuserId(authInfo.id);
      setKaishaId(authInfo.kaisha_id);
      setUpdateDt(null);
    } else {
      // 広告主情報取得
      const res = await getSearchKokyakuId(kokyakuId);
      if (res?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: res?.message,
        });
      }

      setKokyakuMei(res.data[0].kokyaku_mei);
      setJusho(res.data[0].jusho);
      setLoginuserId(res.data[0].loginuser_id);
      setKaishaId(res.data[0].kaisha_id);
      setUpdateDt(res.data[0].update_dt);
    }

    setOpen(true);
  };

  /**
 * エラーメッセージ初期化処理
 */
  const initErrorMessage = () => {
    setErrorMessageKokyakuMei("")
    setErrorMessageJusho("")
  }

  const handleClose = () => {
    setOpen(false);
  };

  // テーブル更新・一覧更新
  const handleUpdate = async () => {
    // バリデーションチェック
    initErrorMessage();

    if (kokyakuMei === "") {
      return setErrorMessageKokyakuMei("広告主名を入力してください。");
    }
    if (jusho === "") {
      return setErrorMessageJusho("住所を入力してください。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setLoad(true);

    const result = await updateKokyaku({
      id: kokyakuId,
      jusho: jusho,
      kokyaku_mei: kokyakuMei,
      loginuser_id: loginuserId,
      kaisha_id: kaishaId,
      update_dt: (updateDt) ? moment(updateDt).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });

    await reloadContext?.setSnackbarInfo({
      isOpen: true,
      type: result.isError ? "error" : "success",
      message: result.message,
    });
    setLoad(false);
    await reloadSetting();
  };

  // モーダル→画面戻り時
  const reloadSetting = () => {
    reloadContext?.setReload(reloadContext?.reload + 1);
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {
        isCreate ?
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            onClick={() => handleClickOpen()}
          >
            新規広告主登録
          </Button>
          :
          <Edit color="primary" fontSize='large'
            onClick={() => handleClickOpen()}
          />
      }
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <form onSubmit={handleSubmit(handleUpdate)}>
          <Typography component="h3" variant="h6" mt={1} ml={2}>
            {isCreate ? "新規広告主登録" : "広告主情報編集"}
          </Typography>
          <DialogContent sx={{ paddingTop: 0 }}>
            <TextField
              label="広告主名 *"
              value={kokyakuMei}
              onChange={e => setKokyakuMei(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageKokyakuMei && (
              <small className={"error-message"}>{errorMessageKokyakuMei}</small>
            )}
            <TextField
              label="住所 *"
              value={jusho}
              onChange={e => setJusho(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ marginTop: 1 }}
            />
            {errorMessageJusho && (
              <small className={"error-message"}>{errorMessageJusho}</small>
            )}

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>閉じる</Button>
            <LoadingButton loading={load} variant="contained" type="submit">登録</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default KokyakuEditDialog;