import { useState, useContext, useRef } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import ExcelJS from 'exceljs';
import moment from "moment";
import axios from "axios";

import { ReloadContext } from "../../context/ReloadContext";
import { AuthInfoContext } from "../../context/AuthContext";
import SimpleDatePicker from '../Common/DatePicker/SimpleDatePicker';
import { getBangumihyoDownloadHizuke, postBangumihyoDownload } from "../../api/bangumihyo";
import { BaitaiShosaiDataSet, BaitaiDataSet, BangumihyoHizukeData, ExcelDownloadDataSet } from "../../types/WebData";
import { KENGEN, TEMPLETE_PATH } from "../../const/index";
import LoadingButton from '@mui/lab/LoadingButton';


interface Props {
  baitai?: BaitaiDataSet | null;
  baitaiShosai?: BaitaiShosaiDataSet | null;
}

/**
 * 番組表ダウンロードダイアログコンポーネント
 *
 * @param {Props} { baitai, baitaiShosai }
 * @return {*} 
 */
const BangumihyoDownloadDialog = ({ baitai, baitaiShosai }: Props) => {
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState<boolean>(false);

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  const handleClickOpen = () => {
    if (!baitaiShosai?.id) {
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: "画面を選択してください。",
      });
    }
    setErrorMessage("");
    setStartDate(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
 * Excelダウンロード処理
 *
 * @return {*} 
 */
  const handleBangumihyoDownload = async () => {
    // バリデーションチェック
    if (!startDate) {
      return setErrorMessage("放映開始日を入力してください。");
    }
    if (!moment(startDate).isValid()) {
      return setErrorMessage("不正な入力形式です。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    setLoad(true);
    const resultBangumihyoHizukeList = await getBangumihyoDownloadHizuke(moment(startDate).format('YYYYMMDD'), baitaiShosai?.id)
    if (resultBangumihyoHizukeList.isError) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: resultBangumihyoHizukeList.message,
      });
    }

    // v1.1.4 両面放映を一旦廃止。要望があれば対応する。
    // data[0]にダウンロード対象の配信開始日、data[1]にダウンロード対象の次の配信開始日が格納されている。
    const targetBangumihyo = resultBangumihyoHizukeList.data[0];
    const targetBangumihyoArray = [resultBangumihyoHizukeList.data[0]];
    const nextBangumihyoHizuke = resultBangumihyoHizukeList.data[1]?.bangumihyo_haishinbi;
    const bangumihyoHizuke = moment(targetBangumihyo.bangumihyo_haishinbi).format('YYYYMMDD');
    // const bangumihyoHizuke = moment(resultBangumihyoHizukeList.data.find((resultBangumihyoHizuke: BangumihyoHizukeData) => resultBangumihyoHizuke.baitai_shosai_id === baitaiShosai?.id).bangumihyo_haishinbi).format('YYYYMMDD');
    // 番組表データ取得
    const resultBangumihyoDatas = await postBangumihyoDownload(targetBangumihyoArray, authInfo.kaisha_id, authInfo.shimei, authInfo.kengen, baitai?.id, baitaiShosai?.id);
    // const resultBangumihyoDatas = await postBangumihyoDownload(resultBangumihyoHizukeList.data, authInfo.kaisha_id, authInfo.shimei, authInfo.kengen, baitai?.id, baitaiShosai?.id);

    if (resultBangumihyoDatas.isError) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: resultBangumihyoDatas.message,
      });
    }

    // 番組表Excel出力データが0件だった場合、処理終了
    if (resultBangumihyoDatas.data.length === 0) {
      setLoad(false);
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "warning",
        message: `入力された日付より過去の番組表はありません。`,
      });
    }

    // テンプレートを読み込み
    const template = await axios.get(TEMPLETE_PATH.BANGUMIHYO_EXCEL, {
      responseType: "arraybuffer",
    })

    // Excelデータ作成
    const BangumihyoExcel = await createBangumihyoExcel(resultBangumihyoDatas.data, bangumihyoHizuke, nextBangumihyoHizuke, authInfo.kaisha_id, authInfo.kengen, template.data);

    // Excelダウンロード
    const blob = new Blob([BangumihyoExcel], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `番組表_${baitai?.baitai_mei}_${baitaiShosai?.gamen_mei}_${bangumihyoHizuke}.xlsx`;
    a.click();
    a.remove();
    setLoad(false);
    setOpen(false);
  }

  const createBangumihyoExcel = async (
    bangumihyoDatas: ExcelDownloadDataSet[],
    haishinKaishibi: string,
    haishinKaishibiNext: string,
    kaishaId: number,
    kengen: number,
    template: ArrayBuffer
  ) => {
    // --- Excel作成処理 start ---
    // 番組表生成制御関連
    let programRowNum: number;      // 番組表入力行制御用
    let programColumnNum: number;   // 番組表入力列制御用
    let getProgramNoColumnNum = () => programColumnNum;   // 列番号　No
    let getTantouColumnNum = () => programColumnNum + 1;   // 列番号　担当者
    let getKaishaMeiColumnNum = () => programColumnNum + 2;   // 列番号　会社名（素材名）
    let getContentByosuColumnNum = () => programColumnNum + 3;   // 列番号　コンテンツ秒数
    let getHaishinKaishibiColumnNum = () => programColumnNum + 4;   // 列番号　放映開始日
    let getHaishinShuryobiColumnNum = () => programColumnNum + 5;   // 列番号　放映終了日
    let getBikoColumnNum = () => programColumnNum + 6;   // 列番号　備考
    let programNo;          // 番組表No制御用
    let programTimeNum;     // 番組表1シート毎の放送時間カウント用
    let programTimeTotalNum;// 番組表総放送時間カウント用

    //初期化
    programRowNum = 4
    programColumnNum = 2
    programNo = 1
    programTimeNum = 0
    programTimeTotalNum = 0;

    const templateFile = new Uint8Array(template);
    const workbook = new ExcelJS.Workbook();
    // テンプレートファイル読込
    await workbook.xlsx.load(templateFile);

    const gamenMei = bangumihyoDatas[0].gamen_mei;

    // 番組表部分生成
    let row;
    for (const data of bangumihyoDatas) {
      const worksheet = workbook.getWorksheet(`番組表`);
      worksheet.pageSetup = { orientation: 'portrait' };

      // ヘッダー部生成
      // ビジョン名設定
      if (!worksheet.getCell('A1').value) {
        worksheet.getCell('A1').value = `${baitai?.baitai_mei} ${gamenMei} 番組表`;
      }
      // ビジョンの画面サイズ、解像度、配信時間帯を設定
      if (!worksheet.getCell('D2').value) {
        worksheet.getCell('D2').value = `${baitaiShosai?.size}（${baitai?.hoei_kaishi_jikan}～${baitai?.hoei_shuryo_jikan}）`;
      }
      // 開始日設定
      if (!worksheet.getCell('H1').value) {
        const kaishibi = moment(haishinKaishibi).format("YYYY/MM/DD");
        const shuryobi = haishinKaishibiNext ? moment(haishinKaishibiNext).subtract(1, 'day').format("YYYY/MM/DD") : "";
        worksheet.getCell('H1').value = `${kaishibi}－${shuryobi}`;
      }
      // 番組数
      if (!worksheet.getCell('H2').value) {
        const bangumiSu = Array.from(new Set<number>(bangumihyoDatas.map(b => b.hoeimoshikomi_id))).length;
        worksheet.getCell('H2').value = `番組数：${bangumiSu}`;
      }

      // データ設定先の列番号を取得
      const programNoColumnNum = getProgramNoColumnNum();
      const tantouColumnNum = getTantouColumnNum();
      const kaishaMeiColumnNum = getKaishaMeiColumnNum();
      const contentByosuColumnNum = getContentByosuColumnNum();
      const haishinKaishibiColumnNum = getHaishinKaishibiColumnNum();
      const haishinShuryobiColumnNum = getHaishinShuryobiColumnNum();
      const bikoColumnNum = getBikoColumnNum();

      // 秒数からセル結合の行数を設定
      const combineNum = data.content_byosu / 15;

      if (combineNum > 1) { // セル結合が必要な場合
        // セル結合処理
        worksheet.mergeCells(programRowNum, programNoColumnNum, programRowNum + combineNum - 1, programNoColumnNum);
        worksheet.mergeCells(programRowNum, tantouColumnNum, programRowNum + combineNum - 1, tantouColumnNum);
        worksheet.mergeCells(programRowNum, kaishaMeiColumnNum, programRowNum + combineNum - 1, kaishaMeiColumnNum);
        worksheet.mergeCells(programRowNum, contentByosuColumnNum, programRowNum + combineNum - 1, contentByosuColumnNum);
        worksheet.mergeCells(programRowNum, haishinKaishibiColumnNum, programRowNum + combineNum - 1, haishinKaishibiColumnNum);
        worksheet.mergeCells(programRowNum, haishinShuryobiColumnNum, programRowNum + combineNum - 1, haishinShuryobiColumnNum);
        worksheet.mergeCells(programRowNum, bikoColumnNum, programRowNum + combineNum - 1, bikoColumnNum);
      }

      row = worksheet.getRow(programRowNum);
      // No生成
      row.getCell(programNoColumnNum).value = programNo;
      // 担当生成
      let tantou = ``;
      if (kengen === KENGEN.ADMIN) {
        // CMN管理者がダウンロードした場合、すべての担当名を表示
        tantou = data.tantosha_shimei;
      } else if (kaishaId === data.kanri_kaisha_id) {
        // 画面の管理会社に所属するユーザーがダウンロードした場合、すべての担当名を表示
        tantou = data.tantosha_shimei;
      } else if (kaishaId === data.kaisha_id) {
        // 上記以外はダウンロードしたユーザーと同じ会社の担当名のみを表示
        tantou = data.tantosha_shimei;
      }
      row.getCell(tantouColumnNum).value = tantou;
      // 会社名（素材名）生成
      row.getCell(kaishaMeiColumnNum).value = `${data.bangumi_mei}${data.content_file_mei ? `\n` + data.content_file_mei : ""}`;
      // コンテンツ秒数生成
      row.getCell(contentByosuColumnNum).value = data.content_byosu;
      // 放映開始日生成
      row.getCell(haishinKaishibiColumnNum).value = moment(data.haishin_kaishibi).format("M月D日");
      // 放映終了日生成
      row.getCell(haishinShuryobiColumnNum).value = data.haishin_shuryobi ? moment(data.haishin_shuryobi).format("M月D日") : "なし";
      // 備考生成
      let biko = "";
      // 時間帯指定
      if (data.jikantai_shitei_flg) {
        // 既に備考がある場合、改行する
        biko = (biko === "") ? "" : biko + "\n";
        biko = biko + `右記の時間帯のみ放映 ${data.haishin_kaishijikan}-${data.haishin_shuryojikan}`;
      }
      // 番組表配信年と番組の配信開始年が異なる場合、備考に「YYYY年開始」を出力
      const isDifferentYear = moment(haishinKaishibi).format("YYYY") !== moment(data.haishin_kaishibi).format("YYYY");
      if (isDifferentYear) {
        // 既に備考がある場合、改行する
        biko = (biko === "") ? "" : biko + "\n";
        biko = biko + `${moment(data.haishin_kaishibi).format("YYYY")}年放映開始`;
      }
      row.getCell(bikoColumnNum).value = biko;

      // 次の準備
      programNo = programNo + 1;
      programRowNum = programRowNum + combineNum;
      programTimeNum = programTimeNum + combineNum;
      programTimeTotalNum = programTimeTotalNum + combineNum;
      // --- Excel作成処理 end ---
    }
    return await workbook.xlsx.writeBuffer();
  }

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Button
        type="button"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ ml: 1, mt: 2, mb: 2 }}
      >
        番組表ダウンロード
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50vw",
          }
        }}
      >
        <Typography component="h3" variant="h6" mt={2} ml={2}>
          番組表ダウンロード
        </Typography>
        <DialogContent>
          <SimpleDatePicker
            date={startDate}
            setDate={setStartDate}
            label="放映開始日 *"
          />
          {errorMessage && (
            <small style={{ color: "red", marginLeft: "16px" }}>{errorMessage}</small>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
          <LoadingButton loading={load} variant="contained" onClick={handleBangumihyoDownload}>ダウンロード</LoadingButton>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

export default BangumihyoDownloadDialog;
