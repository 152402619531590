import { HoeiKaisuSearchParam } from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";

/**
 * hoei_kaisuテーブルAPI
 * 放映回数情報検索
 *
 * @param {HoeiKaisuSearchParam} searchParam
 * @return {*} 
 */
export const postSearchHoeiKaisu = async (searchParam: HoeiKaisuSearchParam) => {
  const result = await API.post(`/cms-api/hoeiKaisu/search`, searchParam)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });

  return result;
};