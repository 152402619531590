import {
  JackSearchParam,
  JackEditData,
} from "../types/WebData";
import API from "./api";
import * as MESSAGE from "../const/message";
import { HTTP_STATUS_CODE } from "../const/index";

/**
 * jackテーブルAPI get
 *
 * @param {number} jackId
 */
export const getJackId = async (jackId: number) => {
  const result = await API.get(`/cms-api/jack/id`, { params: { id: jackId } })
    .then((res) => {
      return { data: res.data.data, isError: false, message: "" };
    })
    .catch((error) => {
      return { data: [], isError: true, message: MESSAGE.SYSTEM_ERROR };
    });

  return result;
};

/**
 * jackテーブルAPI
 * ジャック検索
 *
 * @param {JackSearchParam} searchParam
 * @return {*} 
 */
export const postSearchJack = async (searchParam: JackSearchParam) => {
  const result = await API.post(`/cms-api/jack/search`, searchParam)
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};

/**
 * jackテーブルAPI 更新
 *
 * @param {JackEditData} postData
 * @return 
 */
export const updateJack = async (postData: JackEditData) => {
  if (postData.id === 0) {
    // insert
    const result = await API.post("/cms-api/jack", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.TOROKU_SEIKO, jackMeiKaburiList: res.data };
      })
      .catch((error) => {
        return { isError: true, message: MESSAGE.SYSTEM_ERROR, jackMeiKaburiList: [] };
      });
    return result;
  } else {
    // update
    const result = await API.put("/cms-api/jack/id", postData)
      .then((res) => {
        return { isError: false, message: MESSAGE.KOSHIN_SEIKO, jackMeiKaburiList: res.data };
      })
      .catch((error) => {
        const status = error.response.status;
        const message = status === HTTP_STATUS_CODE.CONFLICT_ERROR ? MESSAGE.CONFLICT_ERROR : MESSAGE.SYSTEM_ERROR;
        return { isError: true, message: message, jackMeiKaburiList: [] };
      });
    return result;
  }
};

/**
 * jackテーブルAPI
 * ジャック削除
 *
 * @param {number} id
 * @return {*} 
 */
export const deleteJack = async (id: number) => {
  const result = await API.put(`/cms-api/jack/delete/id`, { id: id })
    .then((res) => {
      return { isError: false, message: "" };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR };
    });
  return result;
};


/**
 * jackテーブルAPI
 * ジャック 配信処理
 *
 * @param {number} id
 * @param {string} haishin_kaishibi
 * @return {*} 
 */
export const postJackHaishin = async (id: number, haishin_kaishibi?: string) => {
  const result = await API.put(`/cms-api/jack/haishin`, { id, haishin_kaishibi })
    .then((res) => {
      return { isError: false, message: "配信に成功しました。" };
    })
    .catch((error) => {
      let message = '';
      const jackHaishinError = error.response.data.jackHaishinError;
      switch (jackHaishinError) {
        case 'timeOver':
          message = `ジャックを配信するには配信開始時間制限前までに行ってください。`;
          break;
        case 'noContent':
          message = `コンテンツ未登録の媒体があります。コンテンツをアップロードするかジャックする画面を確認してください。`;
          break;
        default:
          message = MESSAGE.SYSTEM_ERROR;
      }
      return { isError: true, message };
    });
  return result;
};

/**
 * jackテーブルAPI
 * ジャック開始時間検索
 *
 * @param {JackSearchParam} searchParam
 * @return {*} 
 */
export const postSearchJackKaishijikan = async (baitai_id: number) => {
  const result = await API.post(`/cms-api/jack/kaishijikan`, { baitai_id })
    .then(
      (res) => {
        return { isError: false, message: "", data: res.data };
      })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: [] };
    });
  return result;
};