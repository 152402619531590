import {
  PlaylistPlay,
  Tv,
  Assignment,
  Apartment,
  HomeWork,
  People,
  AspectRatio,
  Videocam,
  VerifiedUser,
  Filter,
  WbIridescent,
  Business,
  VideoSettings,
  Person,
  PinDrop,
  VideoLibrary,
  Subscriptions
} from "@mui/icons-material";

type Props = {
  iconName: string;
};

/**
 * アイコンコンポーネント
 * サイドメニューのアイコン表示に使用。
 *
 * @param {Props} { iconName }
 * @return {*} 
 */
const MuiIcon = ({ iconName }: Props) => {
  return iconName === "PlaylistPlay" ? (
    <PlaylistPlay />
  ) : iconName === "Tv" ? (
    <Tv />
  ) : iconName === "Videocam" ? (
    <Videocam />
  ) : iconName === "VerifiedUser" ? (
    <VerifiedUser />
  ) : iconName === "AspectRatio" ? (
    <AspectRatio />
  ) : iconName === "Assignment" ? (
    <Assignment />
  ) : iconName === "Apartment" ? (
    <Apartment />
  ) : iconName === "People" ? (
    <People />
  ) : iconName === "HomeWork" ? (
    <HomeWork />
  ) : iconName === "Filter" ? (
    <Filter />
  ) : iconName === "Business" ? (
    <Business />
  ) : iconName === "WbIridescent" ? (
    <WbIridescent />
  ) : iconName === "Person" ? (
    <Person />
  ) : iconName === "VideoSettings" ? (
    <VideoSettings />
  ) : iconName === "PinDrop" ? (
    <PinDrop />
  ) : iconName === "VideoLibrary" ? (
    <VideoLibrary />
  ) : iconName === "Subscriptions" ? (
    <Subscriptions />
  ) : (
    <p>--</p>
  );
};

export default MuiIcon;
