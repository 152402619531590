import { useState, useEffect, useRef, useContext } from "react";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Box,
  Toolbar,
  Grid,
  Container,
  Paper,
  Typography,
} from "@mui/material";

import moment from "moment";

import { Copyright } from "../components/Common/Copyright";
import { HoeiShomeiGrid } from '../components/HoeiShomei/HoeiShomeiGrid'
import BaitaiSelect from "../components/Common/BaitaiSelect";
import BaitaiShosaiSelect from "../components/Common/BaitaiShosaiSelect";
import HoeiKubunSelect from "../components/Common/HoeiKubunSelect";
import SimpleDatePicker from '../components/Common/DatePicker/SimpleDatePicker';
import { HoeiKubunDataSet, HoeiKaisuSearchParam, BaitaiDataSet, BaitaiShosaiDataSet } from "../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';
import { AuthInfoContext } from "../context/AuthContext";
import { KENGEN, HOEI_KUBUN } from "../const/index";

const mdTheme = createTheme();

/**
 * 放映証明管理画面コンポーネント
 *
 * @return {*} 
 */
export const HoeiShomei = () => {
  const [baitai, setBaitai] = useState<BaitaiDataSet | null>(null);
  const [baitaiShosai, setBaitaiShosai] = useState<BaitaiShosaiDataSet | null>(null);
  const [haishinbi, setHaishinbi] = useState<Date | null>(null);
  const [gamenMei, setGamenMei] = useState<string>("");
  const [searchParam, setSearchParam] = useState<HoeiKaisuSearchParam | null>(null);
  const [authInfo] = useContext(AuthInfoContext);
  const [hoeiKubun, setHoeiKubun] = useState<HoeiKubunDataSet>({ code: HOEI_KUBUN.TSUJYO, hoei_kubun_mei: '' });

  // エラーメッセージ
  const [errorMessageBaitai, setErrorMessageBaitai] = useState<string>("");
  const [errorMessageBaitaiShosai, setErrorMessageBaitaiShosai] = useState<string>("");
  const [errorMessageHaishinbi, setErrorMessageHaishinbi] = useState<string>("");

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  // 媒体詳細情報を初期化
  useEffect(() => {
    if (baitaiShosai?.baitai_id) {
      setBaitaiShosai({ id: 0 });
    }
  }, [baitai])

  // 検索実行
  const handleSearch = async () => {
    setErrorMessageBaitai("");
    setErrorMessageBaitaiShosai("");
    setErrorMessageHaishinbi("");

    // バリデーションチェック
    if (!baitai?.id) {
      return setErrorMessageBaitai("媒体名は必須です。");
    }
    if (!baitaiShosai?.id) {
      return setErrorMessageBaitaiShosai("画面名は必須です。");
    }
    if (!haishinbi) {
      return setErrorMessageHaishinbi("配信日は必須です。");
    }
    if (haishinbi && !moment(haishinbi).isValid()) {
      return setErrorMessageHaishinbi("不正な入力形式です。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    await setSearchParam({
      baitai_shosai_id: baitaiShosai.id,
      hoeibi: moment(haishinbi).format('YYYY/MM/DD'),
      hoei_kubun: hoeiKubun.code
    });
    setGamenMei(baitaiShosai?.gamen_mei ? baitaiShosai.gamen_mei : "");
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mb: 4 }}>
            <Grid container spacing={1} sx={{ mt: 2 }}>
              {/* 検索 */}
              <Grid item xs={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '82vh',
                  }}
                >
                  <Box>
                    <BaitaiSelect
                      baitai={baitai}
                      setBaitai={setBaitai}
                      label="媒体名 *"
                      is_visible={authInfo?.kengen === KENGEN.ADMIN ? true : false}
                      kanri_only_flg={true}
                    />
                    {errorMessageBaitai && (
                      <small className={"error-message"}>{errorMessageBaitai}</small>
                    )}

                    <BaitaiShosaiSelect
                      baitaiId={baitai?.id}
                      baitaiShosai={baitaiShosai}
                      setBaitaiShosai={setBaitaiShosai}
                      label="画面名 *"
                      kanri_only_flg={true}
                    />
                    {errorMessageBaitaiShosai && (
                      <small className={"error-message"}>{errorMessageBaitaiShosai}</small>
                    )}

                    <SimpleDatePicker
                      date={haishinbi}
                      setDate={setHaishinbi}
                      label={"配信日 *"}
                    />
                    {errorMessageHaishinbi && (
                      <small style={{ color: "red", marginLeft: "16px" }}>{errorMessageHaishinbi}</small>
                    )}

                    <HoeiKubunSelect
                      hoeiKubun={hoeiKubun}
                      setHoeiKubun={setHoeiKubun}
                    />

                    <LoadingButton
                      loading={load}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </LoadingButton>
                  </Box>
                </Paper>
              </Grid>
              {/* 一覧 */}
              <Grid item xs={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '82vh',
                  }}
                >
                  <Typography component="h3" variant="subtitle1" sx={{ mb: 1 }}>
                    {gamenMei}
                  </Typography>
                  <HoeiShomeiGrid searchParam={searchParam} setload={setLoad} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}