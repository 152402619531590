import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthContextProvider } from "./context/AuthContext";
import { ReloadProvider } from "./context/ReloadContext";
import { RouterConfig } from "./RouterConfig";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthContextProvider>
    <ReloadProvider>
      <RouterConfig />
    </ReloadProvider>
  </AuthContextProvider>
);