import React, { useState, useContext, useEffect } from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormHelperText } from "@mui/material";

import { ReloadContext } from "../../context/ReloadContext";
import { KengenData } from "../../types/WebData";
import { getKengen } from "../../api/kengen";

type Props = {
  kengen: number;
  setKengen: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * 権限ラジオボタンコンポーネント
 *
 * @param {Props} { kengen, setKengen }
 * @return {*} 
 */
const KengenRadio = ({ kengen, setKengen }: Props) => {
  const [kengenData, setKengenData] = useState<KengenData>({ data: [] });
  const reloadContext = useContext(ReloadContext);
  const [value, setValue] = React.useState(kengen);

  // 初期表示
  useEffect(() => {
    // 権限マスタ取得
    const getKengenListData = async () => {
      const kengens = await getKengen();
      if (kengens.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: kengens.message,
        });
      }
      setKengenData(kengens);
    }
    getKengenListData();
  }, [])

  useEffect(() => {
    setKengen(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number((event.target as HTMLInputElement).value));
  };

  return (
    <FormControl focused variant="outlined">
      <FormHelperText sx={{ mb: 2 }}>
        <span style={{ color: "blue" }}>
          権限を選択後、利用するメニューを選択してください
        </span>
      </FormHelperText>
      <FormLabel id="demo-row-radio-buttons-group-label">権限</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {kengenData.data.map((kengen) => (
          <FormControlLabel
            key={kengen.id}
            value={kengen.id}
            control={<Radio />}
            label={kengen.kengen_mei}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default KengenRadio;
