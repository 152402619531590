import React, { useState, useContext, useEffect } from "react";

import {
  DataGrid,
  GridColDef,
  jaJP,
} from "@mui/x-data-grid";

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { renderCellExpand } from "../Common/GridCellExpand";
import { LocationEditDialog } from './LocationEditDialog'
import ToggleVisibilityDialog from '../Common/ToggleVisibilityDialog'
import {
  LocationData, LocationDataSet, LocationSearchParam
} from "../../types/WebData";
import { postSearchLocation } from "../../api/location";

/**
 * DataGrid用にデータを編集
 *
 * @param {LocationDatas[]} locationDatas
 * @return {*} 
 */
const makeGridData = (locationDatas: LocationDataSet[]) => {
  return locationDatas.map((locationData) => {
    // 日付フォーマット
    const updateDate = moment(locationData.update_dt);
    return {
      ...locationData,
      update_dt: updateDate.format("YYYY/MM/DD"),
    };
  });
};

/**
 * 全件データを１ページ分の行にsliceしている
 * 値に単位をつけるなどのデータの編集も、ここで１ページ分のみ行う
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: LocationDataSet[]
): Promise<LocationDataSet[]> =>
  new Promise<LocationDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceした後に、データを編集するfunctionに渡している
      makeGridData(allRows.slice(page * pageSize, (page + 1) * pageSize))
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = React.useState<number | undefined>(undefined);
  const [data, setData] = React.useState<LocationDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  searchParam: LocationSearchParam | null;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 設置場所一覧コンポーネント
 *
 * @return {Props} { searchParam }
 */
export const LocationGrid = ({ searchParam, setload }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [locationData, setLocationData] = useState<LocationData>({ data: [] });

  useEffect(() => {
    const getSearchData = async (param: LocationSearchParam) => {
      const result = await postSearchLocation(param);
      setLocationData(result);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
      setload(false);
    };

    if (!searchParam) {
      getSearchData({ location_mei: "", is_visible: false })
    } else {
      getSearchData(searchParam);
    }
  }, [searchParam, reloadContext?.reload]);

  const columns: GridColDef[] =
    [
      {
        field: "id",
        headerName: "ID",
        hide: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "location_mei",
        headerName: "設置場所名",
        width: 250,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "area_mei",
        headerName: "エリア名",
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "jusho",
        headerName: "住所",
        width: 300,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "kaisha_mei",
        headerName: "会社名",
        width: 200,
        sortable: false,
        disableColumnMenu: true,
        renderCell: renderCellExpand,
      },
      {
        field: "update_dt",
        headerName: "更新日",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: 'editButton',
        headerName: "",
        width: 20,
        align: "center",
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: (params) => (
          <LocationEditDialog
            LocationId={params.row.id} />
        )
      },
      {
        field: "toggleVisibilityDialog",
        headerName: "",
        width: 20,
        align: "center",
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: (params) => (
          <ToggleVisibilityDialog deleteId={params.row.id} isVisible={!params.row.del_flg} tableName="location" />
        ),
      },
    ];

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    locationData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        {...rowsState}
        rowsPerPageOptions={[10]}
        pagination
        paginationMode="server"
        sortModel={[{ field: "id", sort: "asc" }]}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};