import { useContext, useState, useRef } from "react";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Box,
  Toolbar,
  Grid,
  TextField,
  Container,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";

import { Copyright } from "../components/Common/Copyright";
import { UserSettingGrid } from "../components/UserSetting/UserSettingGrid";
import { UserSettingEditDialog } from "../components/UserSetting/UserSettingEditDialog";
import { AuthInfoContext } from "../context/AuthContext";
import { KENGEN } from "../const/index";

import { UserSettingSearchParam } from "../types/WebData";

const mdTheme = createTheme();

/**
 * 担当者設定画面コンポーネント
 *
 * @return {*} 
 */
export const UserSetting = () => {
  const [authInfo] = useContext(AuthInfoContext);

  const [kaishaMei, setKaishaMei] = useState<string>("");
  const [shimei, setShimei] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<UserSettingSearchParam | null>(null);

  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  // 検索実行
  const handleSearch = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);

    await setSearchParam({
      kaisha_mei: kaishaMei,
      shimei: shimei,
      user_id: userId,
      is_visible: isVisible
    });
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mb: 4 }}>
            {authInfo.kengen === KENGEN.ADMIN && <UserSettingEditDialog loginuserId={0} />}
            <Grid container spacing={1}>
              {/* 検索 */}
              {authInfo.kengen === KENGEN.ADMIN
                ?
                <Grid item xs={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '75vh',
                    }}
                  >
                    <Box>
                      <TextField
                        margin="normal"
                        fullWidth
                        label="会社名"
                        value={kaishaMei}
                        onChange={e => setKaishaMei(e.target.value)}
                      />
                      <TextField
                        margin="normal"
                        fullWidth
                        label="担当者名"
                        value={shimei}
                        onChange={e => setShimei(e.target.value)}
                        sx={{ marginTop: 1 }}
                      />
                      <TextField
                        margin="normal"
                        fullWidth
                        label="担当者ID"
                        value={userId}
                        onChange={e => setUserId(e.target.value)}
                        sx={{ marginTop: 1 }}
                      />
                      <FormGroup>
                        <FormControlLabel control={
                          <Switch
                            value={isVisible}
                            onChange={e => setIsVisible((e.target.value === "true") ? false : true)}
                          />} label="非表示データを表示する" />
                      </FormGroup>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => handleSearch()}
                      >
                        検索
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
                : ''}

              {/* 一覧 */}
              <Grid item xs={authInfo.kengen === KENGEN.ADMIN ? 9 : 12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <UserSettingGrid searchParam={searchParam} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}