import { useState, useRef, useEffect, useContext } from "react";
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import { getJackContentDownload } from "../../api/jackContent";
import { ReloadContext } from "../../context/ReloadContext";
import axios from "axios";
import * as MESSAGE from "../../const/message";

import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { SmartDisplay } from '@mui/icons-material';

import Player from "../BangumihyoShosai/Player"

interface Props {
  contentId: number;
  bangumiMei: string;
  contentFileMei: string;
  contentFilePath: string;
  contentByosu: number;
  contentFileExtension: string;
  kaizodo: string;
}

/**
 * コンテンツ再生ダイアログコンポーネント
 *
 * @param {Props} { contents, selectedRow }
 * @return {*} 
 */
const ContentSaiseiDialog = ({ contentId, bangumiMei, contentFileMei, contentFilePath, contentByosu, contentFileExtension, kaizodo }: Props) => {
  const reloadContext = useContext(ReloadContext);
  const [open, setOpen] = useState(false);
  const playerRef = useRef<VideoJsPlayer | undefined>(undefined);
  const [imageContentUrl, setImageContentUrl] = useState<string>("");
  const [videoJsOptions, setVideoJsOptions] = useState<videojs.PlayerOptions | undefined>(undefined);
  const NO_IMAGE_PATH = `${process.env.PUBLIC_URL}/NoImage.png`;

  useEffect(() => {
    if (videoJsOptions) {
      handleReplay()
    }
  }, [])

  /**
   *　player初期化完了後処理
   *  先頭のコンテンツ終了時イベントを設定する
   *
   * @param {VideoJsPlayer} player
   */
  const handlePlayerReady = (player: VideoJsPlayer) => {
    playerRef.current = player;
  };

  /**
   * ダイアログ表示時処理
   *
   * @return {*} 
   */
  const handleClickOpen = async () => {
    // 先頭のコンテンツを設定した後、ダイアログ表示する
    const videoJsOptions: videojs.PlayerOptions = {
      controls: true,
      muted: false,
      autoplay: false, // 自動再生OFF
      loop: false, // ループ再生OFF
    };

    let resultContentFilePath = '';
    try {
      // キャンセルトークン生成
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      // 署名付きURLを取得
      const result = await getJackContentDownload(source, contentId, contentFilePath);
      resultContentFilePath = result.data.data;

    } catch (error) {
      handleClose();
      return reloadContext?.setSnackbarInfo({
        isOpen: true,
        type: "error",
        message: MESSAGE.SYSTEM_ERROR,
      });
    }

    const extension = contentFileExtension.toLowerCase();

    const type = (extension === "mp4" || extension === "mpg") ? "movie" : "image";
    if (type === "image") {
      if (resultContentFilePath) {
        setImageContentUrl(resultContentFilePath);
      } else {
        // コンテンツが無い場合、デフォルトのNoImage画像を表示
        setImageContentUrl(NO_IMAGE_PATH);
      }
    } else {
      setImageContentUrl("");
      videoJsOptions.sources = [{
        src: resultContentFilePath,
        type: `video/${extension}`
      }]
    };

    setVideoJsOptions(videoJsOptions)

    // ダイアログ表示
    setOpen(true);
  };

  /**
   * ダイアログを閉じる
   *
   * @return {*} 
   */
  const handleClose = () => {
    setVideoJsOptions(undefined)
    setOpen(false);
  };

  const handleReplay = () => {
    replayByIndex(0);
  };

  /**
   * コンテンツ再生処理
   * 引数のindexから順番に再生する
   *
   * @param {number} contentIndexSelected
   * @return {*} 
   */
  const replayByIndex = (contentIndexSelected: number) => {
    if (!playerRef.current) return;
    const player = playerRef.current;

    const extension = contentFileExtension;
    const type = (extension === "mp4" || extension === "mpg") ? "movie" : "image";
    if (type === "image") {
      if (contentFilePath) {
        setImageContentUrl(contentFilePath);
      } else {
        // コンテンツが無い場合、デフォルトのNoImage画像を表示
        setImageContentUrl(NO_IMAGE_PATH);
      }
    } else {
      player.src({
        src: contentFilePath,
        type: `video/${extension}`
      });
      setImageContentUrl("");
      player.play();
    }
  }

  return (
    <>
      <IconButton
        size="large"
        disabled={!contentFileMei}
        color="inherit"
      >
        <SmartDisplay color={!contentFileMei ? "disabled" : "primary"} fontSize='large'
          onClick={() => handleClickOpen()}
        />
      </IconButton>

      <Dialog
        open={open}
        PaperProps={{
          sx: {
            maxWidth: '1600px',
            width: "90vw",
            height: "90vh",
            padding: '8px'
          }
        }}
      >
        <Box mb={2} sx={{ maxWidth: '500px' }}>
          <Typography component="h3" variant="h5" mt={1} ml={2}
            sx={{ background: 'linear-gradient(transparent 60%, #a8eaff 100%)' }}>
            コンテンツプレビュー再生
          </Typography>
          <Typography component="h4" variant="h5" mt={2} ml={2}>
            解像度：{kaizodo}
          </Typography>
          <Typography component="h5" variant="h5" mt={1} ml={2}>
            {contentFileMei}
          </Typography>
        </Box>

        <DialogContent sx={{
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}>
          <Box sx={{
            display: 'flex',
            flexFlow: 'column',
            width: '-webkit-fill-available',
            maxWidth: '80%',
            maxHeight: '100%',
          }}>
            {
              <>
                <svg id="svg" width={'100%'} height={'720px'} viewBox="0 0 1280 720" xmlns="http://www.w3.org/2000/svg" style={{ display: `${imageContentUrl ? "block" : "none"}` }}>
                  <image id="image" width={'100%'} height={'100%'} href={imageContentUrl} />
                  <g id="g" />
                </svg>
                <Box sx={{ display: `${imageContentUrl === "" ? "block" : "none"}` }}>
                  <Player options={videoJsOptions} onReady={handlePlayerReady} />
                </Box>
              </>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog >
    </ >
  );
}

export default ContentSaiseiDialog;