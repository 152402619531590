import { useContext, useEffect, useState, useRef } from "react";

import { useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import {
  Box,
  Toolbar,
  Typography,
} from "@mui/material";

import { Copyright } from "../components/Common/Copyright";
import BaitaiSelect from "../components/Common/BaitaiSelect";
import BaitaiShosaiSelect from '../components/Common/BaitaiShosaiSelect';
import DogaSaiseiDialog from '../components/Common/Preview/DogaSaiseiDialog';
import { Content } from '../components/Common/Preview/data';
import BangumihyoGrid from "../components/Bangumihyo/BangumihyoGrid";
import BangumihyoDownloadDialog from '../components/Bangumihyo/BangumihyoDownloadDialog';
import BangumihyoCreateDialog from '../components/Bangumihyo/BangumihyoCreateDialog';
import HaishinConfirmDialog from '../components/Bangumihyo/HaishinConfirmDialog';

import { KENGEN } from "../const/index";
import { AuthInfoContext } from "../context/AuthContext";
import { BangumihyoSearchParam, BangumihyoDataSet, BaitaiDataSet, BaitaiShosaiDataSet, BangumihyoShosaiProps } from "../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';

const mdTheme = createTheme();

/**
 * 番組表管理画面コンポーネント
 *
 * @return {*} 
 */
export const Bangumihyo = () => {
  const location = useLocation();
  const state = location.state as BangumihyoShosaiProps;

  const [authInfo] = useContext(AuthInfoContext);

  const [errorMessageBaitai, setErrorMessageBaitai] = useState<string>("");
  const [errorMessageBaitaiShosai, setErrorMessageBaitaiShosai] = useState<string>("");

  const [baitai, setBaitai] = useState<BaitaiDataSet | null>(null);
  const [baitaiShosai, setBaitaiShosai] = useState<BaitaiShosaiDataSet | null>(null);
  const [baitaiShosaiMei, setBaitaiShosaiMei] = useState<string>("");
  const [searchParam, setSearchParam] = useState<BangumihyoSearchParam | null>(null);
  const [contents, setContents] = useState<Content[]>([]);
  const [selectedRow, setSelectedRow] = useState<BangumihyoDataSet | null>(null);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    // 検索条件復元
    if (state?.searchParam) {
      setBaitai({
        id: state?.searchParam.baitai_id,
        baitai_mei: state?.baitai_mei,
        hoei_kaishi_jikan: state?.hoei_kaishi_jikan,
        hoei_shuryo_jikan: state?.hoei_shuryo_jikan
      });
      setBaitaiShosai({
        id: state?.searchParam.baitai_shosai_id,
        gamen_mei: state?.gamen_mei,
        size: state?.size,
        kaisha_id: state?.searchParam.kaisha_id
      })
      setBaitaiShosaiMei(state?.gamen_mei)
      setSearchParam(state?.searchParam);
    }
  }, [])

  useEffect(() => {
    if (selectedRow) {
      setContents(selectedRow.contents);
    } else {
      setContents([]);
    }
  }, [selectedRow])

  // 媒体、媒体詳細セレクトボックスを切り替えたら見出しの画面名をクリアする
  useEffect(() => {
    if ((baitai || baitaiShosai) && (state?.searchParam.baitai_id !== baitai?.id || state?.searchParam.baitai_shosai_id !== baitaiShosai?.id)) {
      setBaitaiShosaiMei("");
      setSelectedRow(null);
    }
  }, [baitai, baitaiShosai])

  // 媒体詳細情報を初期化
  useEffect(() => {
    if (baitaiShosai?.baitai_id) {
      setBaitaiShosai({ id: 0 });
    }
  }, [baitai])

  // 検索実行
  const handleSearch = async () => {
    setErrorMessageBaitai("");
    setErrorMessageBaitaiShosai("");

    // バリデーションチェック
    if (!baitai?.id) {
      return setErrorMessageBaitai("媒体名は必須です。");
    }
    if (!baitaiShosai?.id) {
      return setErrorMessageBaitaiShosai("画面名は必須です。");
    }

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    const searchParam: BangumihyoSearchParam = {
      baitai_id: baitai.id,
      baitai_shosai_id: baitaiShosai.id,
      kaisha_id: authInfo.kaisha_id
    };
    await setSearchParam(searchParam);
    setBaitaiShosaiMei(baitaiShosai?.gamen_mei ? baitaiShosai.gamen_mei : "")
  };

  /**
   * 新規作成ボタン 表示非表示判定
   */
  const isShowBangumihyoCreateDialog = () => {
    return (!baitai?.del_flg && (authInfo?.kengen === KENGEN.ADMIN || authInfo?.kengen === KENGEN.OMAKASE || (authInfo?.kaisha_id === baitaiShosai?.kaisha_id && !baitaiShosai?.omakase_pack_flg)));
  }

  /**
   * 配信ボタン 表示非表示判定
   */
  const isShowHaishinConfirmDialog = () => {
    let result = false;
    if (!baitai?.del_flg) {
      // 配信担当者 かつ 管理者
      if (authInfo?.haishin_tanto_flg && (authInfo?.kengen === KENGEN.ADMIN || authInfo?.kengen === KENGEN.OMAKASE)) {
        result = true;
      }
      // 配信担当者 かつ ログインユーザーの所属会社=選択中の媒体詳細の担当会社
      if (authInfo?.haishin_tanto_flg && authInfo?.kaisha_id === baitaiShosai?.kaisha_id && !baitaiShosai.omakase_pack_flg) {
        result = true;
      }
    }
    return result;
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mt: 2, mb: 4 }}>

            <Grid container spacing={1}>
              {/* 検索 */}
              <Grid item xs={3} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <Box>
                    <BaitaiSelect
                      baitai={baitai}
                      setBaitai={setBaitai}
                      label="媒体名 *"
                      is_visible={authInfo?.kengen === KENGEN.ADMIN ? true : false}
                    />
                    {errorMessageBaitai && (
                      <small className={"error-message"}>{errorMessageBaitai}</small>
                    )}

                    <BaitaiShosaiSelect
                      baitaiId={baitai?.id}
                      baitaiShosai={baitaiShosai}
                      setBaitaiShosai={setBaitaiShosai}
                      label="画面名 *"
                    />
                    {errorMessageBaitaiShosai && (
                      <small className={"error-message"}>{errorMessageBaitaiShosai}</small>
                    )}
                    <LoadingButton
                      loading={load}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </LoadingButton>
                  </Box >
                </Paper >
              </Grid >
              {/* 一覧 */}
              < Grid item xs={9} md={8} lg={9} pl={0} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <Grid container spacing={1}>
                    {/* タイトル */}
                    <Grid item xs={5} pl={0}>
                      <Box
                        display='flex'
                        alignItems='center'
                        height='100%'>
                        <Typography component="h1" variant="h5">
                          {baitaiShosaiMei}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* ボタンエリア */}
                    <Grid item xs={7}>
                      <Box
                        display="flex"
                        justifyContent="end">
                        {isShowBangumihyoCreateDialog() ?
                          <BangumihyoCreateDialog baitai={baitai} baitaiShosai={baitaiShosai} searchParam={searchParam} />
                          : ""}
                        <BangumihyoDownloadDialog baitai={baitai} baitaiShosai={baitaiShosai} />
                        <DogaSaiseiDialog contents={contents} selectedRow={selectedRow} haishinKaishibi={selectedRow ? selectedRow.haishin_kaishibi : ''} bangumihyouFlg={true} />
                        {isShowHaishinConfirmDialog() ?
                          <HaishinConfirmDialog selectedRow={selectedRow} baitaiShosaiId={baitaiShosai?.id} kaishaId={baitaiShosai?.kaisha_id} />
                          : ""}
                      </Box>
                    </Grid>
                  </Grid>
                  <BangumihyoGrid baitai={baitai} baitaiShosai={baitaiShosai} searchParam={searchParam} setSelectedRow={setSelectedRow} setload={setLoad} />
                </Paper>
              </Grid >
            </Grid >
            <Copyright />
          </Container >
        </Box >
      </Box >
    </ThemeProvider >
  );
}
