import { useState, useRef } from "react";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  Box,
  Toolbar,
  Grid,
  TextField,
  Container,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { Copyright } from "../components/Common/Copyright";
import { KaishaEditDialog } from '../components/Kaisha/KaishaEditDialog'
import { KaishaGrid } from '../components/Kaisha/KaishaGrid'
import { KaishaSearchParam } from "../types/WebData";
import LoadingButton from '@mui/lab/LoadingButton';

const mdTheme = createTheme();

/**
 * 会社管理画面コンポーネント
 *
 * @return {*} 
 */
export const Kaisha = () => {
  const [kaishaMei, setKaishaMei] = useState<string>("");
  const [jusho, setJusho] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<KaishaSearchParam | null>(null);
  const [load, setLoad] = useState<boolean>(false);
  // 処理中フラグ（ボタン連打対策）
  const processing = useRef(false);

  // 検索実行
  const handleSearch = async () => {
    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) return;
    // 処理中フラグを上げる
    processing.current = true;
    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 1000);
    setLoad(true);
    await setSearchParam({
      kaisha_mei: kaishaMei,
      jusho: jusho,
      is_visible: isVisible
    });
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth={false} sx={{ mb: 4 }}>
            <KaishaEditDialog KaishaId={0} />
            <Grid container spacing={1}>
              {/* 検索 */}
              <Grid item xs={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <Box>
                    <TextField
                      margin="normal"
                      fullWidth
                      label="会社名"
                      value={kaishaMei}
                      onChange={e => setKaishaMei(e.target.value)}
                      sx={{ marginTop: 2 }}
                    />

                    <TextField
                      margin="normal"
                      fullWidth
                      label="住所"
                      value={jusho}
                      onChange={e => setJusho(e.target.value)}
                      sx={{ marginTop: 1 }}
                    />

                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          value={isVisible}
                          onChange={e => setIsVisible((e.target.value === "true") ? false : true)}
                        />} label="非表示データを表示する" />
                    </FormGroup>

                    <LoadingButton
                      loading={load}
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </LoadingButton>
                  </Box>
                </Paper>
              </Grid>
              {/* 一覧 */}
              <Grid item xs={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '75vh',
                  }}
                >
                  <KaishaGrid searchParam={searchParam} setload={setLoad} />
                </Paper>
              </Grid>
            </Grid>

            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider >
  );
}