import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Item, ItemWithIndex, ItemTypes, MoveHandler } from '../Common/Preview/data';

const Draggable: React.FC<{
  item: Item,
  index: number,
  onMove: MoveHandler,
  children: React.ReactNode,
  draggingItem: Item | null;
  setDraggingItem: React.Dispatch<React.SetStateAction<Item | null>>;
}> = ({
  item, index, onMove, children, draggingItem: draggingItem_, setDraggingItem
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
      // acceptに指定したtypeだけがコールバックへの対象となる (本サンプルでは ['todo', 'doing', 'done'])
      accept: ItemTypes,
      // マウスドラッグをしたときにhoverした部分でのコールバックを定義
      hover(dragItem: ItemWithIndex, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = dragItem.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }

        if (item.group === dragItem.group) {
          // グループ内での並び替えの場合は入れ替え方向とhover位置に応じて入れ替えるかを確定
          const hoverRect = ref.current.getBoundingClientRect();
          const hoverMiddleX = (hoverRect.right - hoverRect.left) / 2;
          const mousePosition = monitor.getClientOffset();
          if (!mousePosition) return;
          const hoverClientX = mousePosition.x - hoverRect.left;
          if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX * 0.9) return;
          if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX * 1.1) return;
        }

        // 内部のデータも変更しつつ、onMoveでstate変更を依頼する
        if (draggingItem) { // setStateが間に合っていない場合は無処理とする
          onMove(dragIndex, hoverIndex, item.group);
          dragItem.index = hoverIndex;
          dragItem.group = item.group;
        }
      }
    });

    const draggingItem = (draggingItem_) ? draggingItem_ : item;
    // collectでmonitorから取得したデータのみが戻り値として利用できる (collectに指定することで型補完も適用される)
    const [{ isDragging, canDrag }, drag] = useDrag({
      type: item.type, //Contains data about the item we're trying to drag
      //Item replaced Begin in the new version of React DnD
      item: { ...draggingItem, index },
      isDragging: monitor => {
        return monitor.getItem().id === item.id
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag(),
      }),
    })

    useEffect(() => {
      if (!isDragging) return;
      if (!draggingItem_) {
        setDraggingItem(item);
      }
    }, [isDragging])

    // refをconnectorと呼ばれる関数(drag,drop)に渡すことで、対象refと↑のuseDrag,useDropでの処理を結びつける
    drag(drop(ref));

    const onMouseDown = () => {
      setDraggingItem(null);
    }

    return (
      <div
        ref={ref}
        style={{
          opacity: isDragging ? 0.4 : 1,
          cursor: canDrag ? 'move' : 'default',
        }}
        onMouseDown={onMouseDown}
      >
        {children}
      </div>
    );
  };

export default Draggable;