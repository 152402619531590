import { useEffect, useState, useContext } from 'react';

import { ReloadContext } from "../../context/ReloadContext";
import { postSearchTentoShomei } from "../../api/tentoShomei";
import TentoShomeiListItem from "./TentoShomeiListItem";
import { TentoShomeiData, TentoShomeiSearchParam } from "../../types/WebData";

import TentoShomeiListModule from './TentoShomeiList.module.css';

type Props = {
  searchParam: TentoShomeiSearchParam | null;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 点灯証明写真リストコンポーネント
 *
 * @param {Props} { searchParam }
 * @return {*} 
 */
const TentoShomeiList = ({ searchParam, setload }: Props) => {
  const [tentoShomeiData, setTentoShomeiData] = useState<TentoShomeiData>({ data: [] });
  const reloadContext = useContext(ReloadContext);

  // 初期表示処理
  useEffect(() => {
    // 検索ボタン押下時
    const getSearchData = async () => {
      if (!searchParam) return setload(false);
      const result = await postSearchTentoShomei(searchParam);
      setload(false);
      if (result.isError) {
        return reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      } else {
        setTentoShomeiData(result);
      }
    }
    getSearchData();
  }, [searchParam]);

  return (
    <div className={TentoShomeiListModule["group"]}>
      <div className={TentoShomeiListModule["list-container"]}>
        <ul className={TentoShomeiListModule['list']}>
          {tentoShomeiData.data.map((tentoShomei, index) => {
            return (
              <li key={index} className={TentoShomeiListModule['item-wrapper']}>
                {/* 点灯証明写真コンポーネント */}
                <TentoShomeiListItem tentoShomei={tentoShomei} />
              </li>
            );
          })}
        </ul>
      </div >
    </div >
  );
};

export default TentoShomeiList;