import API from "./api";
import * as MESSAGE from "../const/message";

/**
 * baitaiKikiAssociateテーブルAPI 存在チェック
 *
 * @param {number} kiki_kataban_id
 */
export const isBaitaiKikiAssociate = async (kiki_kataban_id: number) => {
  const result = await API.post("/cms-api/baitaiKikiAssociate/count", { kiki_kataban_id: kiki_kataban_id })
    .then((res) => {
      return { isError: false, message: Number(res.data) > 0 ? MESSAGE.EXISTENCE_BAITAI_KIKI_ASSOCIATE : '', data: res.data };
    })
    .catch((error) => {
      return { isError: true, message: MESSAGE.SYSTEM_ERROR, data: '' };
    });
  return result;
};