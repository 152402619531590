import { useEffect, useState, createContext } from "react";
import lscache from "lscache";
import { KENGEN, Kengen } from "../const/index";
import { MenuData } from "../types/WebData";

/**
 * 認証情報の型
 */
type AuthInfo = {
  id: number;
  user_id: number;
  shimei: string;
  kengen: Kengen;
  kaisha_id: number;
  haishin_tanto_flg: boolean;
  accessToken?: string;
  refleshToken?: string;
  menuData?: MenuData;
};

const initialAuthInfo = {
  id: 0,
  user_id: 0,
  shimei: "",
  kengen: KENGEN.DAIRITEN,
  kaisha_id: 0,
  haishin_tanto_flg: false,
};

// ログイン状態のContext
export const LoggedInContext = createContext<boolean>(false);

// 認証情報と認証情報セットのContext
export const AuthInfoContext = createContext<
  [
    AuthInfo,
    React.Dispatch<React.SetStateAction<AuthInfo>>,
    React.Dispatch<React.SetStateAction<boolean>>
  ]
>([initialAuthInfo, () => { }, () => { }]);

/**
 * デフォルトのAuthInfoを取得
 * ローカルストレージから取得できた場合はその値をパース
 * 取得できない場合は空の情報を返す
 * @returns
 */
function getDefaultAuthInfo(): AuthInfo {
  if (typeof window !== "undefined") {
    const defaultAuthInfo = lscache.get("authInfo");
    const accessToken = lscache.get("accessToken");
    const refleshToken = lscache.get("refleshToken");

    if (defaultAuthInfo) {
      const authInfo = {
        ...defaultAuthInfo,
        accessToken: accessToken,
        refleshToken: refleshToken
      }
      return authInfo as AuthInfo;
    } else {
      return initialAuthInfo;
    }
  }
  return initialAuthInfo;
}

/**
 * 認証情報をローカルストレージに追加
 * @param authInfo
 */
export function setAutoInfoToLocalStorage(authInfo: AuthInfo): void {
  // localStorageの有効期限30分
  // （補足）API呼び出しの度に有効期限リセット→API操呼び出し無い状態が30分続いたらログアウト。
  lscache.set("authInfo",
    {
      id: authInfo.id,
      user_id: authInfo.user_id,
      shimei: authInfo.shimei,
      kengen: authInfo.kengen,
      kaisha_id: authInfo.kaisha_id,
      haishin_tanto_flg: authInfo.haishin_tanto_flg,
      menuData: authInfo.menuData
    }
    , 30);

  // localStorageの有効期限 24時間
  lscache.remove("accessToken");
  lscache.set("accessToken", authInfo.accessToken);
  // localStorageの有効期限 25時間
  lscache.remove("refleshToken");
  lscache.set("refleshToken", authInfo.refleshToken);
}

type Props = {
  children: React.ReactNode;
};

export const AuthContextProvider: React.FC<Props> = (props) => {
  // stateの定義
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [authInfo, setAuthInfo] = useState<AuthInfo>(() => getDefaultAuthInfo());

  // authInfoのバリデーション
  useEffect(() => {
    // authInfoに正しく値がセットされているかどうかをチェック
    if (authInfo?.user_id !== 0) {
      setAutoInfoToLocalStorage(authInfo);
      setLoggedIn(true);
    } else {
      // ログアウト実行の場合
      lscache.remove("authInfo");
      lscache.remove("accessToken");
      lscache.remove("refleshToken");
      setLoggedIn(false);
    }
  }, [authInfo]);

  return (
    <LoggedInContext.Provider value={loggedIn}>
      <AuthInfoContext.Provider value={[authInfo, setAuthInfo, setLoggedIn]}>
        {props.children}
      </AuthInfoContext.Provider>
    </LoggedInContext.Provider>
  );
};
