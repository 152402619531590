/**
 * 機器種別コード
 */
export const KIKI_SHUBETSU = {
  CAMERA: { code: 1, shubetsu_mei: "カメラ" },
  GYRO_SENSOR: { code: 2, shubetsu_mei: "ジャイロセンサー" },
  LPWA: { code: 3, shubetsu_mei: "LPWA" },
  STB: { code: 4, shubetsu_mei: "STB" },
  LTE_ROUTER: { code: 5, shubetsu_mei: "LTEルーター" },
};

/**
 * コードマスタカテゴリ
 * AWS_ACCESS_KAY：AWS-SDKアクセスキー
 * AWS_SECRET_KAY：AWS-SDK シークレットキー
 * AWS_REGION：AWS-S3 リージョン
 * AWS_S3_BUCKET：AWS-S3 バケット名
 * KIKI_SHUBETSU：機器種別
 * KIKI_HIMOZUKESAKI：機器紐づけ先
 * INTERVAL_OSHIRASE：お知らせ再取得間隔
 * TEMPLETE: テンプレート
 * JACK: ジャック
 * HOEI_KUBUN: 放映区分
 * 
 */
export const CODE_MASTER_CATEGORY = {
  AWS_ACCESS_KAY: 1,
  AWS_SECRET_KAY: 2,
  AWS_REGION: 3,
  AWS_S3_BUCKET: 4,
  KIKI_SHUBETSU: 5,
  KIKI_HIMOZUKESAKI: 6,
  INTERVAL_OSHIRASE: 7,
  TEMPLETE: 8,
  JACK: 9,
  HOEI_KUBUN: 10,
};

/**
 * 放映区分ステータス 
 * TSUJYO:通常放映
 * JACK:ジャック
 * */
export const HOEI_KUBUN = {
  TSUJYO: 1,
  JACK: 2,
};

/**
 * テンプレートコード
 * BANGUMIHYO_EXCEL: 番組表Excel
 * 
 */
export const TEMPLETE_CODE = {
  BANGUMIHYO_EXCEL: 1,
}

/**
 * テンプレートパス
 * BANGUMIHYO_EXCEL: 番組表Excel
 * 
 */
export const TEMPLETE_PATH = {
  BANGUMIHYO_EXCEL: '/template/bangumihyo.xlsx',
}

/**
 * 点灯証明ステータス
 * SEIJO:正常
 * NG_GIWAKU:NG疑惑
 * IJO:異常
 * 
 * */
export const TENTO_SHOMEI_STATUS = {
  SEIJO: 0,
  NG_GIWAKU: 1,
  IJO: 2,
};

/**
 * 機器紐づけ先コード
 */
export const KIKI_HIMODUKESAKI_CODE = {
  BAITAI_SHOSAI: { code: 1, name: "画面" },
  BAITAI: { code: 2, name: "媒体" },
};
export type KikiHimozukesakiCode = typeof KIKI_HIMODUKESAKI_CODE[keyof typeof KIKI_HIMODUKESAKI_CODE];

/**
 * 権限 
 * ADMIN:システム管理
 * DAIRITEN:広告代理店
 * KANRI:管理会社
 * 
 * @type {Kengen}
 * */
export const KENGEN = {
  ADMIN: 1,
  DAIRITEN: 2,
  KANRI: 3,
  OMAKASE: 4,
};

export type Kengen = typeof KENGEN[keyof typeof KENGEN];

/**
 * メニュー種別 
 * GYOMU:業務メニュー
 * SETTING:設定メニュー
 * 
 * @type {MenuType}
 * */
export const MENU_TYPE = {
  GYOMU: 1,
  SETTING: 2,
};

export type MenuType = typeof MENU_TYPE[keyof typeof MENU_TYPE];

/**
 * コンテンツ　アップロードステータス 
 * UPLOADING:アップロード中
 * UPLOAD_FAILED:アップロード失敗
 * UPLOAD_SUCCESS:アップロード成功
 * 
 * @type {UploadStatus}
 * */
export const UPLOAD_STATUS = {
  UPLOADING: 0,
  UPLOAD_FAILED: 1,
  UPLOAD_SUCCESS: 2,
};

export type UploadStatus = typeof UPLOAD_STATUS[keyof typeof UPLOAD_STATUS];

/**
 * ジャックコンテンツ　アップロードステータス 
 * BEFOER_UPLOAD:アップロード前
 * UPLOADING:アップロード中
 * UPLOAD_FAILED:アップロード失敗
 * UPLOAD_SUCCESS:アップロード成功
 * 
 * */
export const JACK_UPLOAD_STATUS = {
  BEFOER_UPLOAD: 0,
  UPLOADING: 1,
  UPLOAD_FAILED: 2,
  UPLOAD_SUCCESS: 3,
};

export type JackUploadStatus = typeof JACK_UPLOAD_STATUS[keyof typeof JACK_UPLOAD_STATUS];

/**
 * お知らせレベル 
 * INFO:重要度 低
 * NOTICE:重要度 中
 * WARNING:重要度 高
 * 
 * @type {OshiraseLevel}
 * */
export const OSHIRASE_LEVEL = {
  INFO: 0,
  NOTICE: 1,
  WARNING: 2,
};

export type OshiraseLevel = typeof OSHIRASE_LEVEL[keyof typeof OSHIRASE_LEVEL];
/**
 * 番組表　作成ステータス 
 * SAKUSEICHU: 作成中
 * SAKUSEIZUMI: 作成済
 * HAISHIN_YOTEI: 配信済,
 * HAISHINCHU: 放映中,
 * HAISHIN_SHURYO: 放映終了,
 * MIHAISHIN: 未配信,
 * 
 * @type {BangumihyoStatus}
 * */
export const BANGUMIHYO_STATUS = {
  SAKUSEICHU: 0,
  SAKUSEIZUMI: 1,
  HAISHIN_YOTEI: 2,
  HAISHINCHU: 3,
  HAISHIN_SHURYO: 4,
  MIHAISHIN: 5,
};

export type BangumihyoStatus = typeof BANGUMIHYO_STATUS[keyof typeof BANGUMIHYO_STATUS];

/** アップロード可能なmime-type @type {string[]} */
export const ALLOW_MIME_TYPES = ['video/mp4', 'video/mpeg', 'image/jpeg', 'image/png'];

/** アップロード可能な動画のmime-type @type {string[]} */
export const ALLOW_MOVIE_MIME_TYPES = ['video/mp4', 'video/mpeg'];

/** アップロード可能な動画の拡張子 @type {string[]} */
export const ALLOW_MOVE_EXTENTIONS = ['mp4', 'mpe', 'mpeg', 'mpg'];

/**
 * 画面名
 */
export const GAMEN_MEI_LIST = ["A", "B", "C", "D", "E"];

/**
 * HTTPステータスコード 
 * OK:成功
 * SYSTEM_ERROR:システムエラー
 * CONFLICT_ERROR:競合エラー
 * VALIDATION_ERROR:バリデーションエラー
 * */
export const HTTP_STATUS_CODE = {
  OK: 200,
  SYSTEM_ERROR: 400,
  CONFLICT_ERROR: 409,
  VALIDATION_ERROR: 422,
}

/**
 * カスタムエラーコード 
 * DB_UNIQUE_ERROR:DBユニークエラー
 * */
export const CUSTOM_ERROR_CODE = {
  DB_UNIQUE_ERROR: 400001,
}

/**
 * ジャック　作成ステータス 
 * SAKUSEICHU: 作成中,
 * HAISHINZUMI: 配信済,
 * HAISHIN_SHURYO: 放映終了,
 * MIHAISHIN: 未配信,
 * 
 * @type {JackStatus}
 * */
export const JACK_STATUS = {
  SAKUSEICHU: 0,
  HAISHINZUMI: 1,
  HAISHIN_SHURYO: 2,
  MIHAISHIN: 3,
};

export type JackStatus = typeof JACK_STATUS[keyof typeof JACK_STATUS];
